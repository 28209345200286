/* You can add global styles to this file, and also import other style files */

/*
Default Style
============================*/
$body-family: 'Roboto', sans-serif;
$body-color: #666666;
$main-color: #13316e;
$green-color: #0E6A6A;
$heading-color: #222222;
$white-color: #ffffff;
$black-color: #000000;
$font-size: 17px;
$transition: all ease .5s;
$border-radius: 4px;
$box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

body {
    font-size: $font-size;
    font-family: $body-family;
	color: $body-color;
	background-color: #f9f9f9;

	&.white-body {
		background-color: $white-color;
	}
}

a {
	display: inline-block;
    transition: $transition;
	text-decoration: none;
	color: $body-color;

	&:hover {
        text-decoration: none;
        color: $main-color;
	}
	&:focus {
		text-decoration: none;
	}
}
button {
    outline: 0 !important;
	box-shadow: none;
	border: none;

    &:focus {
        box-shadow: none;
    }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $heading-color;
	font-weight: 700;
}
h3 {
	font-size: 22px;
	line-height: 1.4;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
p {
    font-size: $font-size;
	margin-bottom: 15px;
	line-height: 1.8;
	&:last-child {
        margin-bottom: 0;
    }
}
img {
    max-width: 100%;
    height: auto;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.form-control {
    height: 50px;
    color: $main-color;
	border: 1px solid #ebebeb;
	background-color: transparent;
	border-radius: 0;
	font-size: 16px;
	padding: 10px 20px;
	width: 100%;
	&::placeholder {
		color: #676a6a;
	}
	&:focus {
		color: $black-color;
		background-color: transparent;
		box-shadow: unset;
		outline: 0;
		border: 1px solid $main-color;
	}
}
.form-control:hover, .form-control {
    &:focus {
        box-shadow: unset;
    }
}
textarea {
    &.form-control {
        height: auto;
    }
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.ptb-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-minus-100 {
	margin-top: -100px;
}
.mt-30 {
	margin-top: 30px;
}
.mb-30 {
	margin-bottom: 30px;
}

/*
Bg-color Style*/
.bg-color {
	background-color: #f9f9f9;
}
.fdfcf8-bg-color {
	background-color: #fdfcf8;
}

/*
Default Btn Area Style*/
.default-btn {
	font-size: 16px;
    color: $white-color;
	padding: 15px 40px;
    line-height: 1;
    transition: $transition;
	text-align: center;
	background-color: $main-color;
	border-radius: $border-radius;
	border: 1px solid $main-color;
	position: relative;
	z-index: 1;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background-color: $heading-color;
		z-index: -1;
		transition: $transition;
	}
	&:hover {
		color: $white-color;
		border-color: $main-color;
		background-color: $main-color;
		&::before {
			width: 50%;
			left: 0;
			right: auto;
		}
	}
}

/*
Read More Btn Area Style*/
.read-more {
	font-weight: 600;
	font-size: 15px;
	color: $heading-color;
	&:hover {
		color: $main-color;
	}
}

/*
Section Title Area Style*/
.section-title {
	// max-width: 750px;
	margin:-5px auto 50px;
	text-align: center;
	position: relative;
	span {
		font-size: 30px;
		display: block;
		margin-bottom: 15px;
		color: $main-color;
		font-weight: 500;
	}
	h2 {
		font-size: 25px;
		margin-bottom: 15px;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&.text-align-left {
		text-align: left;
		margin-left: 0;
	}
	&.white-title {
		span {
			color: $main-color;
		}
		h2 {
			color: $white-color;
		}
		p {
			color: $white-color;
		}
	}
}
.section-btn {
	margin-bottom: 50px;
	position: absolute;
	right: 15px;
	bottom: 0;
}
.col-lg-6 {
	position: relative;
}

/*
Header Area Style
======================================================*/
.top-header {
	padding: {
		top: 15px;
		bottom: 15px;
	}
	.header-left-content {
		line-height: 1;
		li {
			display: inline-block;
			margin-right: 15px;
			&:last-child {
				margin-right: 0;
			}
			a {
				i {
					color: #999999;
					display: inline-block;
					font-size: 20px;
					transition: $transition;
					width: 30px;
					height: 30px;
					line-height: 30px;
					border-radius: 50%;
					border: 1px solid #999999;
					text-align: center;
				}
				&:hover {
					i {
						color: $white-color;
						background-color: $main-color;
						border-color: $main-color;
					}
				}
			}
		}
	}
	.header-right-content {
		float: right;
		line-height: 1;
		li {
			display: inline-block;
			margin-right: 30px;
			color: #999999;
			font-size: 14px;
			position: relative;
			padding-left: 60px;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 44px;
				width: 1px;
				height: 100%;
				background-color: #d7d7d7;
			}
			&:last-child {
				margin-right: 0;
			}
			span {
				display: block;
				font-weight: 400;
				font-size: 18px;
				color: $body-color;
				margin-top: 10px;
			}
			a {
				display: block;
				font-weight: 400;
				font-size: 18px;
				color: $body-color;
				margin-top: 10px;
			}
			i {
				color: $main-color;
				display: inline-block;
				margin-right: 10px;
				font-size: 30px;
				position: absolute;
				top: 6px;
				left: 0;
			}
			a {
				&:hover {
					color: $main-color;
				}
			}
		}
	}
	&.top-header-two {
		background-color: $main-color;
		.header-left-content {
			li {
				a {
					i {
						color: $white-color;
					}
					&:hover {
						i {
							background-color: $white-color;
							color: $main-color;
							border-color: $white-color;
						}
					}
				}
			}
		}
		.header-right-content {
			li {
				color: #f9f9f9;
				span {
					color: $white-color;
				}
				a {
					color: $white-color;
				}
				i {
					color: $white-color;
				}
			}
		}
	}
	&.top-header-three {
		overflow: hidden;
		background-color: #eaecf1;
		.appointment {
			padding-left: 0;
			top: -12px;
			z-index: 1;
			&::after {
				display: none;
			}
			&::before {
				content: "";
				position: absolute;
				bottom: -30px;
				left: 0;
				width: 100%;
				height: 75px;
				background-color: $main-color;
				z-index: -1;
			}
			a {
				margin-top: 0;
				padding: 0 20px;
				color: $white-color;
				&:hover {
					color: $white-color;
				}
				i {
					position: relative;
					color: $white-color;
				}
			}
		}
	}
}

/*
Nav Area Style
======================================================*/
.navbar-area {
	.main-nav {
		background-color: $green-color;
		padding-top: 0;
		padding-bottom: 0;
		.navbar {
			padding: 0;
			.navbar-brand {
				font-size: 0;
				padding: 0;
			}
		}
		nav {
			.navbar-nav {
				.nav-item {
					position: relative;
					padding: 0 0;
					a {
						font-size: 15px;
						font-weight: 600;
						color: $white-color;
						text-transform: capitalize;
						transition: $transition;
						margin-left: 0;
						margin-right: 0;
						padding: 35px 0;
						position: relative;
						z-index: 1;
						margin: 0 15px;
						i {
							font-size: 22px;
							line-height: 0;
							position: relative;
							top: 4px;
						}
						&:hover {
							color: $main-color;
						}
						&.active {
							color: $white-color;
						}
					}
					.dropdown-menu {
						position: absolute;
						top: 130%;
						left: 15px;
						opacity: 0;
						width: 250px;
						visibility: hidden;
						z-index: 99;
						display: block;
						padding: 0;
						background-color: $white-color;
						border: none;
						border-radius: 0;
						transition: all 0.2s ease-in-out;
						box-shadow: $box-shadow;
						border-top: 2px solid $main-color;
						li {
							position: relative;
							padding: 0;
							a {
								font-size: 14px;
								color: $heading-color;
								position: relative;
								padding: 10px 15px;
								border-bottom: 1px dashed #eeeeee;
								margin-left: 0;
								margin-right: 0;
								text-transform: capitalize;
								display: block;
								font-weight: 600;
								i {
									float: right;
									top: 12px;
									transition: $transition;
									font-size: 20px;
								}
								&::before {
									display: none;
								}
								&:hover {
									color: $main-color;
								}
								&.active {
									color: $main-color;
								}
							}
							.dropdown-menu {
								left: 100%;
								top: 0;
							}
							&:last-child {
								a {
									border-bottom: none;
								}
							}
							.dropdown-menu {
								position: absolute;
								left: -98%;
								top: 20px !important;
								opacity: 0 !important;
								visibility: hidden !important;
								li {
									.dropdown-menu {
										position: absolute;
										right: -100%;
										top: 0;
										opacity: 0 !important;
										visibility: hidden !important;
									}
									a {
										color: $heading-color;
										text-transform: capitalize;
										font-size: 14px;
										font-weight: 600;
										&:hover {
											color: $main-color;
										}
										&:focus {
											color: $main-color;
										}
										&.active {
											color: $main-color;
										}
									}
								}
							}
							&:hover {
								.dropdown-menu {
									opacity: 1 !important;
									visibility: visible !important;
									top: -4px !important;
									li {
										&:hover {
											.dropdown-menu {
												opacity: 1 !important;
												visibility: visible !important;
												top: -20px !important;
											}
										}
									}
								}
							}
						}
						&:last-child {
							.dropdown-menu {
								left: auto;
								right: -100%;
							}
						}
					}
					&:hover {
						ul {
							opacity: 1;
							visibility: visible;
							top: 92%;
						}
					}
				}
			}
		}
	}
	.others-option {
		.option-item {
			color: $body-color;
			display: inline-block;
			line-height: 1;
			position: relative;
			top: 0;
			.search-overlay {
				display: none;
			}
			.search-overlay.search-popup {
				position: absolute;
				top: 100%;
				width: 300px;
				right: 0;
				background: $white-color;
				z-index: 2;
				padding: 20px;
				box-shadow: $box-shadow;
				margin-top: 20px;
				.search-form {
					position: relative;
					.search-input {
						display: block;
						width: 100%;
						height: 50px;
						line-height: initial;
						border: 1px solid #eeeeee;
						color: $heading-color;
						outline: 0;
						transition: $transition;
						font-size: 15px;
						padding-top: 4px;
						padding-left: 15px;
						&:focus {
							border-color: $main-color;
						}
					}
					.search-button {
						position: absolute;
						right: 0;
						top: 0;
						height: 50px;
						background: $main-color;
						border: none;
						width: 50px;
						outline: 0;
						color: $white-color;
						transition: $transition;
						padding: 0;
						&:hover {
							background-color: $heading-color;
						}
					}
				}
			}
			.search-btn {
				cursor: pointer;
				transition: $transition;
				color: $heading-color;
				font-size: 24px;
				line-height: 1;
				&:hover {
					color: $main-color;
				}
			}
			.close-btn {
				cursor: pointer;
				display: none;
				transition: $transition;
				font-size: 24px;
				line-height: 1;
				text-align: center;
				color: $heading-color;
				&:hover {
					color: $main-color;
				}
			}
			.close-btn.active {
				display: block;
			}
		}
		.cart-icon {
			display: inline-block;
			margin-left: 30px;
			position: relative;
			top: 4px;
			margin-right: 10px;
			a {
				i {
					font-size: 30px;
					transition: $transition;
					line-height: 1;
					line-height: 50px;
					text-align: center;
					display: inline-block;
					color: $heading-color;
					border-radius: 50px;
				}
				span {
					position: absolute;
					top: 5px;
					right: -10px;
					width: 15px;
					height: 15px;
					line-height: 15px;
					background-color: $main-color;
					border-radius: 50%;
					text-align: center;
					color: $white-color;
					font-size: 11px;
				}
				&:hover {
					i {
						color: $main-color;
					}
				}
			}
		}
	}
	&.is-sticky {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		box-shadow: $box-shadow;
		background-color: $white-color !important;
		animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	}
}

/*
Mobile Nav Area Style
======================================================*/
.mobile-nav {
	display: none;
}

/*
Others Option For Responsive Area Style
======================================================*/
.others-option-for-responsive {
	display: none;
	.dot-menu {
		padding: 0 10px;
		height: 30px;
		cursor: pointer;
		z-index: 9991;
		position: absolute;
		right: 60px;
		top: 20px;
		.inner {
			display: flex;
			align-items: center;
			height: 30px;
			.circle {
				height: 5px;
				width: 5px;
				border-radius: 100%;
				margin: 0 2px;
				transition: $transition;
				background-color: $black-color;
			}
		}
		&:hover {
			.inner {
				.circle {
					background-color: $main-color;
				}
			}
		}
	}
	.container {
		position: relative;
		.container {
			position: absolute;
			right: 0;
			top: 10px;
			max-width: 262px;
			margin: auto;
			opacity: 0;
			visibility: hidden;
			box-shadow: $box-shadow;
			transition: $transition;
			z-index: 2;
			padding-left: 15px;
			padding-right: 15px;
		}
		.container.active {
			opacity: 1;
			visibility: visible;
		}
	}
	.option-inner {
		padding: 15px;
		box-shadow: $box-shadow;
		background-color: $white-color;
		.others-option {
			background-color: $white-color;
			border-radius: 30px;
			padding: 10px;
			position: absolute;
			top: 70px;
			right: 0;
			box-shadow: $box-shadow;
			.option-item {
				margin-left: 15px;
				padding-left: 15px;
				position: relative;
				&:first-child {
					margin-left: 0;
					padding-left: 0;
				}
			}
		}
	}
	.sidebar-menu {
		display: inline-block;
		margin-left: 10px;
		a {
			i {
				font-size: 25px;
				background-color: $main-color;
				height: 50px;
				width: 50px;
				line-height: 50px;
				display: inline-block;
				text-align: center;
				color: $white-color;
				border-radius: 50px;
			}
		}
	}
}

/*
Banner Area Style
======================================================*/
.banner-area {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 545px;
	position: relative;
	z-index: 1;
	&.bg-1 {
		background-image: url(assets/img/banner/banner-1.jpg);
	}
	&.bg-2 {
		background-image: url(assets/img/banner/banner-2.jpg);
	}
	&.bg-3 {
		background-image: url(assets/img/banner/banner-3.jpg);
	}
	&.banner-area-three {
		position: relative;
		z-index: 1;
		height: 790px;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $white-color;
			opacity: 0.90;
			z-index: -1;
		}
		.banner-content {
			h1 {
				font-size: 60px;
			}
		}
		.banner-shape-3 {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: -1;
		}
		.address-and-week {
			position: relative;
			top: 0;
			max-width: unset;
		}
	}
}
.banner-content {
	overflow: hidden;
    max-width: 680px;
	margin-right: auto;
	h1 {
		font-size: 70px;
		margin-bottom: 20px;
	}
	p {
		color: $heading-color;
		margin-bottom: 30px;
		font-weight: 500;
	}
}
.appointment-box {
	position: relative;
    top: 110px;
	p {
		background-color: $main-color;
		color: $white-color;
		margin-bottom: 0;
		padding: 20px 30px;
		text-align: center;
	}
	.appointment-form {
		background-color: $white-color;
		box-shadow: $box-shadow;
		padding: 30px 30px;
		.form-group {
			margin-bottom: 20px;
			i {
				position: absolute;
				top: 17px;
				right: 20px;
				line-height: 1;
				font-size: 18px;
				color: $main-color;
				z-index: 99;
			}
			.form-control {
				padding: 10px 20px;
				background-color: $white-color;
				&:focus {
					border-color: $main-color;
				}
			}
		}
		.default-btn {
			width: 100%;
		}
		.nice-select {
			height: 50px;
			width: 100%;
			line-height: 48px;
			font-size: 15px;
			margin-bottom: 20px;
			padding-left: 20px;
			background-color: $white-color;
			border-radius: 0;
			&:focus {
				border-color: $main-color;
			}
			span {
				text-align: left;
				position: relative;
				top: 2px;
			}
			&::after {
				height: 8px;
				width: 8px;
				right: 20px;
			}
			&.open {
				.list {
					height: 250px;
					overflow-y: scroll;
				}
			}
		}
	}
}

/*
Feature Area Style
======================================================*/
.feature-area {
	background-color: $main-color;
	padding-top: 30px;
	padding-bottom: 30px;
}
.single-feature {
	position: relative;
	padding-left: 60px;
	i {
		color: $white-color;
		font-size: 40px;
		line-height: 1;
		position: relative;
		top: 1px;
		margin-right: 20px;
		display: inline-block;
	}
	span {
		color: $white-color;
		font-weight: 500;
		font-size: 20px;
		position: relative;
		top: -4px;
	}
	&.active {
		position: relative;
		z-index: 1;
		&::before {
			content: "";
			position: absolute;
			bottom: -43px;
			left: 0;
			width: 100%;
			height: 130px;
			background-color: $white-color;
			z-index: -1;
		}
		span {
			color: $heading-color;
			padding-left: 70px;
		}
		img {
			width: 50px;
			position: absolute;
			top: -15px;
		}
	}
}

/*
Choose Us Area Style
======================================================*/
.choose-us-area {
	position: relative;
	z-index: 1;
	.section-title {
		text-align: left;
		margin-left: 0;
	}
	.choose-us-shape {
		position: absolute;
		top: 30px;
		right: 0;
		z-index: -1;
	}
}
.choose-us-content {
	h3 {
		font-size: 25px;
		margin-bottom: 15px;
	}
	.default-btn {
		margin-top: 10px;
	}
}

/*
Choose Us Area Two Style
======================================================*/
.choose-us-content-two {
	.top-title {
		font-weight: 500;
		color: $main-color;
		display: block;
		margin-bottom: 20px;
	}
	h2 {
		font-size: 39px;
		margin-bottom: 25px;
	}
	p {
		font-weight: 500;
		margin-bottom: 20px;
	}
	ul {
		li {
			position: relative;
			padding-left: 45px;
			margin-bottom: 20px;
			font-weight: 500;
			color: $heading-color;
			&::before {
				content: "";
				position: absolute;
				top: 20px;
    			left: 15px;
				width: 2px;
				height: 100%;
				background-color: $heading-color;
			}
			span {
				position: absolute;
				top: -5px;
				left: 0;
				font-weight: 600;
				width: 30px;
				height: 30px;
				line-height: 30px;
				border-radius: 50%;
				background-color: #d5dae4;
				text-align: center;
				color: $heading-color;
				transition: $transition;
			}
			&:last-child {
				margin-bottom: 0;
				&::before {
					display: none;
				}
			}
			&:hover {
				span {
					background-color: $main-color;
					color: $white-color;
				}
			}
		}
	}
	.default-btn {
		margin-top: 37px;
	}
}
.choose-us-img-two {
	position: relative;
	z-index: 1;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 210px;
		height: 100%;
		background-color: #f9f9f9;
	}
}
.address-and-week {
	position: absolute;
	top: 30px;
	left: 0;
	max-width: 360px;
	p {
		background-color: $main-color;
		color: $white-color;
		padding: 30px;
		margin-bottom: 0;
	}
	.address-week {
		padding: 30px;
		background-color: $white-color;
		box-shadow: $box-shadow;
		.address {
			margin-bottom: 25px;
			li {
				position: relative;
				padding-left: 30px;
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
				span {
					font-weight: 500;
					color: $heading-color;
				}
				i {
					color: $heading-color;
					line-height: 1;
					font-size: 20px;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
		.week {
			li {
				margin-bottom: 7px;
				padding-bottom: 7px;
				border-bottom: 1px dashed #e3e3e3;
				span {
					float: right;
				}
				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: none;
				}
			}
		}
	}
}

/*
Appointment Area Style
======================================================*/
.appointment-here-form {
	background-color: #c6cfe1;
	box-shadow: $box-shadow;
	padding: 50px;
	padding-bottom: 20px;
	position: relative;
	z-index: 2;
	background-image: url(assets/img/book-now-shape.png);
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
	h3 {
		margin-bottom: 28px;
		line-height: 1;
	}
	.form-group {
		position: relative;
		margin-bottom: 28px;
		i {
			position: absolute;
			top: 17px;
			right: 20px;
			line-height: 1;
			font-size: 18px;
			color: $main-color;
			z-index: 99;
		}
		.form-control {
			padding: 10px 20px;
			background-color: $white-color;
			border-right: none;
			&:focus {
				border-color: $white-color;
			}
		}
	}
	.default-btn {
		width: 100%;
		margin-bottom: 28px;
	}
	.nice-select {
		height: 50px;
		width: 100%;
		line-height: 48px;
		font-size: 15px;
		margin-bottom: 30px;
		padding-left: 20px;
		background-color: $white-color;
		border-radius: 0;
		&:focus {
			border-color: $white-color;
		}
		span {
			text-align: left;
			position: relative;
    		top: 2px;
		}
		&::after {
			height: 8px;
			width: 8px;
			right: 20px;
		}
		&.open {
			.list {
				height: 250px;
				overflow-y: scroll;
			}
		}
	}
}
.table-condensed {
	border-collapse: collapse;
	width: 258px;
	text-align: center;
	.prev {
		background-color: #f2f9fc;
		border-radius: $border-radius;
		transition: $transition;
		cursor: pointer;
		&:hover {
			background-color: $main-color;
		}

	}
	.next {
		background-color: #f2f9fc;
		border-radius: $border-radius;
		transition: $transition;
		cursor: pointer;
		&:hover {
			background-color: $main-color;
		}
	}
	.day {
		transition: $transition;
		cursor: pointer;
		transition: $transition;
		border-radius: $border-radius;
		font-size: 14px;
		&:hover {
			background-color: $main-color;
			color: $white-color;
		}
	}
}
.datepicker.datepicker-dropdown.dropdown-menu.datepicker-orient-left.datepicker-orient-bottom {
	border-radius: 0 !important;
}

/*
Services Area Style
======================================================*/
.services-area {
	position: relative;
	z-index: 1;
	.col-lg-3 {
		&:nth-child(2) {
			.single-services {
				.services-icon {
					background-color: #e9f6eb;
				}
			}
		}
		&:nth-child(3) {
			.single-services {
				.services-icon {
					background-color: #fbe8f1;
				}
			}
		}
		&:nth-child(4) {
			.single-services {
				.services-icon {
					background-color: #eaecf1;
				}
			}
		}
	}
	.services-shape {
		position: absolute;
		top: 100px;
		left: 0;
		z-index: -1;
	}
	&.services-area-two {
		background-image: url(assets/img/services/services-bg.jpg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		z-index: 1;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #eaecf1;
			opacity: 0.85;
			z-index: -1;
		}
		.single-services {
			padding: 0;
			.services-img {
				padding: 10px;
				padding-bottom: 0;
			}
			.services-content {
				padding: 30px;
				padding-top: 28px;
			}
		}
		.owl-theme {
			.owl-dots {
				margin-top: 10px !important;
				line-height: 1;
				.owl-dot {
					span {
						width: 15px;
						height: 15px;
						background: #c6cfe1;
						transition: $transition;
						margin: 0 10px;
					}
					&:hover {
						span {
							background-color: #94a5c6;
						}
					}
					&.active {
						span {
							background-color: #94a5c6;
						}
					}
				}
			}
		}
		.owl-carousel {
			.owl-item img {
				width: unset;
			}
		}
		&.bg-t {
			background-image: none;
			&::before {
				display: none;
			}
		}
	}
	&.services-area-three {
		background-image: none;
		border-top: 1px solid #e5e5e5;
		&::before {
			display: none;
		}
	}
}
.single-services {
	text-align: center;
	background-color: $white-color;
	box-shadow: $box-shadow;
	padding: 30px;
	transition: $transition;
	margin-bottom: 30px;
	.services-icon {
		background-color: #fdeee7;
		padding: 20px;
		border-radius: 50%;
		width: 100px;
		height: 100px;
		margin: 0 auto 20px;
		img {
			width: 100px;
			transition: $transition;
		}
	}
	h3 {
		margin-bottom: 12px;
		a {
			color: $heading-color;
			&:hover {
				color: $main-color;
			}
		}
	}
	&:hover {
		transform: translateY(-5px);
		img {
			transform: rotateY(360deg);
		}
	}
}

/*
About Area Style
======================================================*/
.about-img {
	position: relative;
	.about-img-2 {
		margin-top: -270px;
		margin-left: 75px;
		position: relative;
		.about-post {
			background-color: $white-color;
			box-shadow: $box-shadow;
			padding: 30px;
			display: inline-block;
			position: absolute;
			right: -30px;
			bottom: -30px;
		}
	}
}
.about-content {
	margin-left: 50px;
	.top-title {
		font-weight: 500;
		color: $main-color;
		margin-bottom: 15px;
		display: block;
		margin-top: -5px;
	}
	h2 {
		font-size: 40px;
		margin-bottom: 25px;
	}
	.granted {
		position: relative;
		padding-left: 140px;
		p {
			position: relative;
			top: 11px;
		}
		span {
			position: absolute;
			top: 0;
			left: 0;
			font-size: 50px;
			font-weight: 500;
			color: $main-color;
		}
	}
	.default-btn {
		margin-top: 50px;
	}
}

/*
Before After Area Style
======================================================*/
.before-after-area {
	background-color: #eaecf1;
	position: relative;
	z-index: 1;
	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 50%;
		bottom: 0;
		left: 0;
		background-color: $white-color;
		z-index: -1;
	}
	.before-after-shape {
		position: absolute;
		top: 100px;
		left: 0;
		right: 0;
		z-index: -1;
		margin: auto;
		text-align: center;
	}
}
.before-after-img {
	text-align: center;
	border: 10px solid $white-color;
	box-shadow: $box-shadow;
	max-width: 790px;
	margin: auto;
	position: relative;
	ul {
		position: absolute;
		left: 30px;
		right: 30px;
		bottom: 30px;
		li {
			background-color: $white-color;
			color: $heading-color;
			padding: 5px 20px;
			display: inline-block;
			font-weight: 500;
			float: left;
			&.after {
				float: right;
			}
		}
	}
}

/*
Testimonial Area Style
======================================================*/
.testimonial-area {
	border-bottom: 1px solid #d7d7d7;
	.owl-theme {
		.owl-dots {
			margin-top: 50px !important;
			line-height: 1;
			.owl-dot {
				span {
					width: 15px;
					height: 15px;
					background: #c6cfe1;
					transition: $transition;
					margin: 0 10px;
				}
				&:hover {
					span {
						background-color: #94a5c6;
					}
				}
				&.active {
					span {
						background-color: #94a5c6;
					}
				}
			}
		}
	}
	.owl-carousel {
		.owl-item img {
			width: auto;
		}
	}
	&.testimonial-area-two {
		.testimonial-content {
			text-align: center;
			max-width: 870px;
			margin: 0 auto 30px;
		}
		.owl-carousel {
			.owl-item img {
				margin: auto;
			}
		}
	}
}
.testimonial-img {
	position: relative;
	text-align: center;
	z-index: 1;
	.testimonial-shape {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}
}
.testimonial-content {
	i {
		font-size: 60px;
		color: $main-color;
		text-align: center;
		display: block;
		line-height: 1;
		margin-bottom: 20px;
	}
	p {
		font-size: 30px;
		margin-bottom: 25px;
		color: $heading-color;
	}
	h3 {
		margin-bottom: 10px;
	}
	img {
		width: unset;
	}
}

/*
Doctors Area Style
======================================================*/
.doctors-area {
	.owl-theme {
		.owl-nav {
			margin-top: 0;
			position: absolute;
			right: 0;
			top: -100px;
			.owl-prev {
				margin: 0 !important;
				margin-right: 5px !important;
				i {
					background-color: $white-color;
					font-size: 35px;
					border: 1px solid #c6cfe1;
					color: #c6cfe1;
					padding: 5px 8px;
					transition: $transition;
				}
			}
			.owl-next {
				margin: 0 !important;
				margin-left: 5px !important;
				i {
					background-color: $white-color;
					font-size: 35px;
					border: 1px solid #c6cfe1;
					color: #c6cfe1;
					padding: 5px 8px;
					transition: $transition;
				}
			}
			[class*=owl-] {
				&:hover {
					background-color: transparent;
					i {
						color: $white-color;
						background-color: $main-color;
						border-color: $main-color;
					}
				}
			}
		}
	}
}
.single-doctors {
	transition: $transition;
	margin-bottom: 30px;
	.doctors-img {
		position: relative;
		overflow: hidden;
		ul {
			line-height: 1;
			background-color: rgba(19, 49, 110, 0.9);
			text-align: center;
			padding: 30px;
			margin: 10px;
			position: absolute;
			bottom: -150px;
			left: 0;
			right: 0;
			transition: $transition;
			li {
				display: inline-block;
				padding: 0 5px;
				a {
					i {
						font-size: 20px;
						border: 1px solid $white-color;
						color: $white-color;
						height: 30px;
						width: 30px;
						line-height: 30px;
						border-radius: 50%;
						text-align: center;
						transition: $transition;
					}
					&:hover {
						i {
							background-color: $white-color;
							color: $main-color;
						}
					}
				}
			}
		}
	}
	.doctors-content {
		background-color: #eaecf1;
		padding: 30px;
		span {
			display: block;
			margin-bottom: 5px;
		}
		h3 {
			margin-bottom: 0;
		}
	}
	&.two {
		margin-top: 30px;
	}
	&.four {
		margin-top: 30px;
	}
	&.six {
		margin-top: 30px;
	}
	&:hover {
		transform: translateY(-5px);
		.doctors-img {
			ul {
				bottom: 0;
			}
		}
	}
}

/*
Counter Area Style
======================================================*/
.counter-bg {
	background-color: #eaecf1;
	padding: 50px;
	padding-bottom: 20px;
	border-radius: $border-radius;
	background-image: url(assets/img/counter-bg.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	&.counter-bg-three {
		background-color: transparent;
	}
}
.single-counter {
	position: relative;
	padding-left: 90px;
	margin-bottom: 30px;
	transition: $transition;
	i {
		font-size: 70px;
		color: $main-color;
		line-height: 1;
		color: $main-color;
		position: absolute;
		top: 7px;
		left: 0;
	}
	h2 {
		font-size: 40px;
		color: $main-color;
		line-height: 1;
	}
	h3 {
		margin-bottom: 0;
		font-size: 18px;
		font-weight: normal;
		color: $body-color;
	}
	&:hover {
		transform: translateY(-5px);
	}
}

/*
Online Treatment Area Style
======================================================*/
.online-treatment-area {
	background-color: #eaecf1;
	position: relative;
	z-index: 1;
	.online-treatment-shape-1 {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
	}
	.online-treatment-shape-2 {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
}
.online-treatment-img {
	position: relative;
	margin-right: 30px;
	margin-bottom: 30px;
	z-index: 1;
	&::before {
		content: "";
		position: absolute;
		top: 30px;
		right: -30px;
		width: 100%;
		height: 100%;
		background-color: #c6cfe1;
		z-index: -1;
	}
}
.online-treatment-content {
	margin-left: 30px;
	span {
		font-weight: 500;
		color: $main-color;
		display: block;
		margin-bottom: 15px;
	}
	h2 {
		font-size: 40px;
		margin-bottom: 25px;
	}
	ul {
		li {
			margin-bottom: 20px;
			position: relative;
			font-weight: 500;
			padding-left: 40px;
			&:last-child {
				margin-bottom: 0;
			}
			i {
				position: absolute;
				top: -4px;
				left: 0;
				font-size: 30px;
			}
		}
	}
}

/*
Pricing Area Style
======================================================*/
.single-pricing {
	text-align: center;
	background-color: $white-color;
	box-shadow: $box-shadow;
	padding: 30px;
	transition: $transition;
	margin-bottom: 30px;
	h3 {
		margin-bottom: 15px;
		margin-top: -5px;
	}
	h2 {
		font-size: 40px;
		margin-bottom: 0;
		sub {
			font-size: 25px;
			position: relative;
			top: -3px;
			font-weight: normal;
		}
	}
	span {
		margin-bottom: 15px;
		display: block;
	}
	h4 {
		font-size: 16px;
		margin-bottom: 20px;
		text-decoration: underline;
	}
	ul {
		margin-bottom: 20px;
		li {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&.active {
		background-color: #eaecf1;
	}
	&:hover {
		transform: translateY(-5px);
		background-color: #eaecf1;
	}
}

/*
portfolio Area Style
======================================================*/
.our-portfolio-area {
	background-image: linear-gradient(#f2f3f5, transparent);
}
.single-portfolio {
	transition: $transition;
	position: relative;
	margin-bottom: 30px;
	.portfolio-content {
		background-color: rgba(255, 255, 255, 0.9);
		padding: 30px;
		margin: 10px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		h3 {
			margin-bottom: 0;
			font-size: 20px;
			a {
				color: $heading-color;
				&:hover {
					color: $main-color;
				}
			}
		}
	}
	&:hover {
		transform: translateY(-5px);
	}
}
.shorting {
	.mix {
		display: none;
	}
}
.shorting-menu {
	text-align: center;
	margin-bottom: 40px;
	button.filter {
		background-color: transparent;
		font-weight: 500;
		font-size: 18px;
		color: $heading-color;
	}
	button.filter.active {
		color: $main-color;
	}
}

/*
Partner Area Style
======================================================*/
.partner-area {
	position: relative;
	.partner-bg {
		background-color: $white-color;
		box-shadow: $box-shadow;
		padding: 50px;
		.partner-item {
			line-height: 1;
			text-align: center;
		}
		&.partner-bg-two {
			background-color: #eaecf1;
			border-radius: $border-radius;
		}
	}
	.owl-theme {
		.owl-nav {
			margin-top: 0;
			.owl-prev {
				margin: 0 !important;
				margin-right: 5px !important;
				position: absolute;
				left: -30px;
				top: 50%;
				transform: translateY(-20px);
				i {
					background-color: $white-color;
					font-size: 35px;
					border: 1px solid #c6cfe1;
					color: #c6cfe1;
					padding: 5px 8px;
					transition: $transition;
				}
			}
			.owl-next {
				margin: 0 !important;
				margin-left: 5px !important;
				position: absolute;
				right: -30px;
				top: 50%;
				transform: translateY(-20px);
				i {
					background-color: $white-color;
					font-size: 35px;
					border: 1px solid #c6cfe1;
					color: #c6cfe1;
					padding: 5px 8px;
					transition: $transition;
				}
			}
			[class*=owl-] {
				&:hover {
					background-color: transparent;
					i {
						color: $white-color;
						background-color: $main-color;
						border-color: $main-color;
					}
				}
			}
		}
	}
	.partner-shape {
		position: absolute;
		top: -200px;
		right: 0;
	}
}

/*
Blog Area Style
======================================================*/
.single-blog-post {
	margin-bottom: 30px;
	transition: $transition;
	.blog-img {
		position: relative;
		.date {
			background-color: $main-color;
			display: inline-block;
			padding: 5px 15px;
			text-align: center;
			position: absolute;
			right: 30px;
			bottom: -30px;
			ul {
				li {
					border-bottom: 1px solid #4e638e;
					color: $white-color;
					margin-bottom: 2px;
					padding-bottom: 2px;
					&:last-child {
						margin-bottom: 0;
						padding-bottom: 0;
						border: none;
					}
				}
			}
		}
	}
	.blog-content {
		padding: 30px;
		box-shadow: $box-shadow;
		background-color: $white-color;
		span {
			color: $main-color;
			font-weight: 500;
			font-size: 14px;
			display: block;
			margin-bottom: 19px;
		}
		h3 {
			padding-bottom: 16px;
			margin-bottom: 0;
			font-size: 19px;
			a {
				color: $heading-color;
				&:hover {
					color: $main-color;
				}
			}
		}
		p {
			margin-bottom: 20px;
		}
		ul {
			border-top: 1px solid #eaecf1;
			padding-top: 20px;
			li {
				display: inline-block;
				&.read {
					float: right;
					a {
						i {
							position: relative;
							top: 1px;
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
	&:hover {
		transform: translateY(-5px);
	}
}
.blog-area-three {
	border-top: 1px solid #e5e5e5;
}
.blog-column-one {
	.single-blog-post {
		.blog-content {
			h3 {
				font-size: 25px;
			}
		}
	}
}


/*
Subscribe Area Style
======================================================*/
.subscribe-bg {
	background-color: #c6cfe1;
	padding: 50px;
	padding-bottom: 20px;
	background-image: url(assets/img/subscribe-shape.png);
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
}
.subscribe-content {
	position: relative;
	padding-left: 100px;
	margin-bottom: 30px;
	img {
		position: absolute;
		top: 0;
		left: 0;
	}
}
.newsletter-form {
	position: relative;
	margin-bottom: 30px;
	.form-control {
		background-color: $white-color;
		border-radius: $border-radius;
	}
	.default-btn {
		position: absolute;
		top: 0;
		right: 0;
		height: 50px;
	}
}
#validator-newsletter {
	margin-top: 10px;
	color: #dc3545;
}

/*
Footer Area Style
======================================================*/
.footer-area {
	background-color: $white-color;
	box-shadow: $box-shadow;
	&.footer-area-two {
		background-color: #eaecf1;
	}
}
.single-footer-widget {
	margin-bottom: 30px;
	img {
		margin-bottom: 30px;
	}
	.address {
		position: relative;
		padding-left: 75px;
		span {
			position: absolute;
			top: 0;
			left: 0;
			font-weight: 500;
			color: $heading-color;
		}
	}
	h3 {
		position: relative;
		padding-bottom: 10px;
		margin-bottom: 30px;
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100px;
			height: 2px;
			background-color: #b0bacf;
		}
	}
	ul {
		li {
			margin-bottom: 10px;
			span {
				font-weight: 500;
				color: $heading-color;
			}
			&:last-child {
				margin-bottom: 0;
			}
			a {
				&:hover {
					margin-left: 5px;
				}
			}
		}
	}
	.open-day {
		margin-top: 15px;
		li {
			span {
				float: right;
				font-weight: normal;
				color: $body-color;
				text-transform: uppercase;
			}
		}
	}
}

/*
Copy Right Area Style
======================================================*/
.copy-right-area {
	background-color: $white-color;
	border-top: 1px solid #e7eaf0;
	padding-top: 40px;
	padding-bottom: 40px;
	text-align: center;
	p {
		a {
			color: $main-color;
		}
	}
	&.copy-right-area-two {
		background-color: #eaecf1;
		border-top: 1px solid #bfc7d7;
	}
}

/*====================================================
INNER PAGE STYLE AREA
======================================================*/

/*
Page Title Area Style
=====================================================*/
.page-title-area {
	position: relative;
	overflow: hidden;
	padding-top: 150px;
	padding-bottom: 150px;
	text-align: center;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 1;
	background-color: #eaecf1;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-image: url(assets/img/page-bg/page-bg-shape.png);
		animation: FocuS 5s linear 1s infinite;
		z-index: -1;
	}
	.page-title-content {
		position: relative;
		margin-top: -10px;
		h2 {
			margin-bottom: 15px;
			color: $heading-color;
			font-size: 60px;
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			margin-top: 10px;
			margin-bottom: -5px;
			li {
				display: inline-block;
				position: relative;
				font-size: 16px;
				padding-right: 15px;
    			margin-left: 15px;
				font-weight: 500;
				&::before {
					content: "";
					position: absolute;
					top: 5px;
					right: -3px;
					background-color: $main-color;
					width: 1px;
					height: 15px;
					transform: rotate(25deg);
				}
				&:last-child {
					&::before {
						display: none;
					}
				}
				a {
					color: $body-color;
					&:hover {
						color: $main-color;
					}
				}
			}
			.active {
				color: $main-color;
			}
		}
	}
}
@keyframes FocuS {
	0% {
	  opacity: 0;
	}
	75% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
}

/*
Contact Area Style
=====================================================*/
.contact-area {
	.address-and-week {
		position: unset;
		max-width: unset;
		.address-week {
			.week li {
				margin-bottom: 12px;
				padding-bottom: 12px;
			}
			.address li {
				margin-bottom: 20px;
			}
		}
	}
}
.contact-form {
	background-color: $white-color;
	box-shadow: $box-shadow;
	padding: 30px;
	.contact-title {
		margin-bottom: 30px;
	}
	.form-group {
		margin-bottom: 30px;
	}
}
.list-unstyled {
	color: #dc3545;
	font-size: 14px;
	margin-top: 10px;
}
#msgSubmit {
	margin-top: 20px;
}
.text-success {
    color: #28a745;
}
.hidden {
	display: none;
}
.map-area {
	iframe {
		width: 100%;
		height: 500px;
		border: none;
		overflow: hidden;
		display: block;
	}
}

/*
Online Price Area Style
=====================================================*/
.online-price-img {
	// background-image: url(assets/img/online-price-img.jpg);
	background-image: url(assets/img/online-price-img.f381a902cc5da87a.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
}
.online-price-list {
	box-shadow: $box-shadow;
	background-color: $white-color;
	padding: 40px;
	ul {
		li {
			font-weight: 500;
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
			.one {
				float: right;
				margin-left: 30px;
			}
			.two {
				float: right;
			}
		}
	}
}

/*
FAQ Area Style
======================================================*/
.faq-area {
	.section-title {
		text-align: left;
		max-width: unset;
		margin-bottom: 30px;
	}
}
.faq-bg {
    max-width: 700px;
    margin: auto;
}
.faq-accordion {
	.accordion {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
		.accordion-item {
			border: 0;
			background-color: transparent;
			display: block;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			&.active {
				margin-bottom: 0;
			}
		}
		.accordion-title {
			padding: 15px 30px 15px 0;
			color: $heading-color;
			text-decoration: none;
			position: relative;
			display: block;
			border-bottom: 1px solid #e7e7e7;
			font-size: 18px;
			font-weight: 600;
			background-color: transparent;
			i {
				position: absolute;
				left: unset;
				right: 0;
				top: 17px;
				font-size: 25px;
				transition: $transition;
				background-color: $main-color;
				color: $white-color;
				border-radius: 50%;
			}
		}
		.accordion-title.active {
			i {
				&::before {
					content: "\eaf8";
				}
			}
		}
		.accordion-content {
			display: none;
			position: relative;
			margin-top: -5px;
			padding: 30px;
			padding-bottom: 10px;
			padding-left: 0;
			span {
				font-weight: 500;
				color: $heading-color;
			}
			p {
				margin-bottom: 0;
			}
		}
		.accordion-content.show {
			display: block;
		}
	}
}

/*
Privacy Policy Area Style
=====================================================*/
.privacy-policy {
	max-width: 800px;
	margin: auto;
	.title {
		margin-bottom: 30px;
		text-align: center;
		h2 {
			font-size: 36px;
		}
	}
	.privacy-content {
		margin-bottom: 30px;
		h3 {
			margin-bottom: 15px;
		}
		p {
			margin-bottom: 10px;
		}
	}
	.privacy-content.eight {
		margin-bottom: 0;
		p {
			margin-bottom: 0;
		}
	}
}

/*
Terms Conditions Area Style
=====================================================*/
.terms-conditions {
	max-width: 800px;
	margin: auto;
	.title {
		margin-bottom: 30px;
		text-align: center;
		h2 {
			font-size: 36px;
		}
	}
	img {
		margin-bottom: 30px;
	}
	.conditions-content {
		margin-bottom: 30px;
		h3 {
			margin-bottom: 15px;
		}
		p {
			margin-bottom: 10px;
		}
		ul {
			margin-left: 20px;
			margin-bottom: 15px;
			list-style-type: disc;
			margin-top: 15px;
			li {
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.conditions-content.six {
		margin-bottom: 0;
		p {
			margin-bottom: 0;
		}
	}
}

/*
Coming Soon Area CSS
=====================================================*/
.coming-soon-area {
	// height: 100vh;
    height: 50vh;
	position: relative;
	z-index: 1;
	// background-image: url(assets/img/coming-soon-bg.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 1;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #b0bacf;
		opacity: 0.5;
		z-index: -1;
	}
	.coming-soon-content {
		max-width: 700px;
		border-radius: 5px;
		overflow: hidden;
		text-align: center;
		padding: 40px 60px;
		margin-left: auto;
		margin-right: auto;
		z-index: 1;
		position: relative;
		box-shadow: $box-shadow;
		background-color: rgba(255, 255, 255, 0.9);
		.logo {
			display: inline-block;
		}
		h2 {
			font-size: 48px;
			margin-top: 30px;
			margin-bottom: 0;
		}
		#timer {
			margin-top: 40px;
			div {
				background-color: $heading-color;
				color: $white-color;
				width: 100px;
				height: 105px;
				border-radius: 5px;
				font-size: 40px;
				font-weight: 800;
				margin-left: 10px;
				margin-right: 10px;
				span {
					display: block;
					margin-top: -2px;
					font-size: 17px;
					font-weight: 600;
				}
			}
		}
		form {
			position: relative;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 50px;
			.form-group {
				margin-bottom: 25px;
				width: 100%;
				position: relative;
				.label-title {
					margin-bottom: 0;
					position: absolute;
					display: block;
					left: 0;
					top: 0;
					pointer-events: none;
					width: 100%;
					height: 100%;
					color: $heading-color;
					i {
						position: absolute;
						left: 0;
						transition: $transition;
						top: 9px;
						font-size: 22px;
					}
					&::before {
						content: "";
						display: block;
						position: absolute;
						bottom: 0;
						left: 0;
						width: 0;
						height: 2px;
						transition: $transition;
						background: $main-color;
					}
				}
				.input-newsletter {
					border-radius: 0;
					border: none;
					border-bottom: 1px solid #eeeeee;
					padding: 0 0 0 32px;
					color: $heading-color;
					height: 45px;
					display: block;
					width: 100%;
					transition: $transition;
					font-size: 16px;
					font-weight: 400;
					background-color: transparent;
					&::placeholder {
						color: $body-color;
						transition: $transition;
					}
					&:focus {
						border-color: $main-color;
						outline: 0 !important;
						&::placeholder {
							color: transparent;
						}
					}
				}
			}
			.default-btn {
				border-radius: 0;
				position: relative;
				&::before {
					border-radius: 0;
				}
			}
			.validation-danger {
				margin-top: 15px;
				color: #ff0000;
			}
			.validation-success {
				margin-top: 15px;
			}
			p {
				margin-bottom: 0;
				margin-top: 20px;
			}
		}
	}
	.online-treatment-shape-1 {
		position: absolute;
		top: 0;
		right: 0;
	}
	.online-treatment-shape-2 {
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

/*
404 Error Area Style
=====================================================*/
.error-area {
    text-align: center;
    position: relative;
    z-index: 1;
    .error-content-wrap {
		h1 {
			font-size: 300px;
			line-height: 1;
			font-weight: 700;
			color: $main-color;
			.a {
				display: inline-block;
			}
			.red {
				color: #ff0000;
				display: inline-block;

			}
			.b {
				display: inline-block;
			}
		}
        z-index: 1;
        position: relative;
        h3 {
            margin: 30px 0 0;
			position: relative;
			color: #ff0000;
        }
        p {
			margin: 20px 0 20px;
			font-size: 19px;
			color: $main-color;
        }
    }
}

/*
User Area CSS
=====================================================*/
.user-area-style {
	position: relative;
	z-index: 1;
	.contact-form-action {
		max-width: 600px;
		border-bottom: none;
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 30px;
		margin: auto;
		.account-title {
			margin-bottom: 30px;
			h2 {
				font-size: 30px;
				position: relative;
				line-height: 1;
				padding-left: 0;
				padding-bottom: 15px;
				&::before {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 200px;
					height: 2px;
					background-color: $main-color;
				}
			}
		}
		form {
			.form-condition {
				margin-bottom: 20px;
				.agree-label {
					font-weight: 600;
					a {
						color: $main-color;
					}
				}
			}
			.form-group {
				margin-bottom: 30px;
			}
			.login-action {
				margin-bottom: 30px;
				.log-rem {
					display: inline-block;
					label {
						margin-bottom: 0;
					}
				}
				.forgot-login {
					display: inline-block;
					float: right;
				}
			}
		}
		p {
			margin-top: 25px;
			line-height: 1;
			a {
				color: $heading-color;
				&:hover {
					color: $main-color;
				}
			}
		}
	}
	&.recover-password-area {
		.recover {
			max-width: 600px;
			margin: auto;
			border-top: 1px solid #ebebeb;
			padding-top: 30px;
			.default-btn {
				margin-bottom: 0;
				margin-top: 30px;
			}
		}
		h3 {
			font-size: 40px;
			margin-top: -11px;
		}
		p {
			margin-bottom: 30px;
			line-height: 1.8;
		}
		.now-register {
			float: right;
			margin: 0;
		}
	}
}

/*
Appointment Area CSS
=====================================================*/
.patient-information {
	h3 {
		margin-bottom: 25px;
	}
	.appointment-box {
		position: unset;
		.appointment-form {
			background-color: $white-color;
		}
	}
	.checkbox-content {
		.form-group {
			display: inline-block;
			margin-bottom: 0;
			margin-right: 15px;
			span {
				margin-left: 5px;
			}
		}
	}
	.default-btn {
		margin-top: 10px;
	}
}

/*
Product Area Style
======================================================*/
.showing-result {
	margin-bottom: 30px;
	margin-right: 15px;
	background-color: #eaecf1;
	padding: 5px;
	.showing-top-bar-ordering {
		text-align: right;
		.nice-select {
			color: $main-color;
			border-radius: 0;
			border: none;
			float: unset;
			height: unset;
			line-height: initial;
			padding: 15px 35px 15px 15px;
			font-size: 16px;
			background-color: $white-color;
			box-shadow: $box-shadow;
			.list {
				background-color: $white-color;
				box-shadow: $box-shadow;
				border-radius: 0;
				margin-top: 0;
				width: 100%;
				padding-top: 10px;
				padding-bottom: 10px;
				height: 250px;
    			overflow-y: scroll;
				.option {
					transition: $transition;
					font-size: 16px;
					padding-left: 20px;
					padding-right: 20px;
					&:hover {
						background-color: $main-color !important;
						color: $white-color;
					}
				}
				.option.selected {
					font-weight: 500;
					color: $body-color;
				}
			}
			&:after {
				right: 20px;
			}
		}
	}
	.search-form {
		.search-btn {
			position: absolute;
			top: 0;
			right: 0;
			height: 50px;
			width: 50px;
			line-height: 50px;
			background-color: $main-color;
			color: $white-color;
			font-size: 20px;
		}
		.form-control {
			border: 1px solid #d7d7d7;
			background-color: #f5f6fa;
			font-size: 16px;
			&::placeholder {
				color: #616161;
			}
		}
	}
}
.single-product {
	text-align: center;
	margin-bottom: 30px;
	.product-img {
		position: relative;
		margin-bottom: 20px;
		box-shadow: $box-shadow;
		ul {
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
			transform: scaleY(0);
			transition: $transition;
			li {
				display: inline-block;
				margin: 0 5px;
				a {
					i {
						font-size: 20px;
						width: 40px;
						height: 40px;
						line-height: 40px;
						background-color: $white-color;
						color: $heading-color;
						border-radius: $border-radius;
						transition: $transition;
					}
					&:hover {
						i {
							background-color: $main-color;
							color: $white-color;
						}
					}
				}
			}
		}
		.default-btn {
			bottom: 30px;
			transform: scaleX(0);
			position: absolute;
			left: 0;
			right: 0;
			width: 190px;
			text-align: center;
			margin: auto;
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $main-color;
			opacity: 0.5;
			transform: scaleY(0);
			transition: $transition;
		}
	}
	a {
		h3 {
			font-size: 20px;
			transition: $transition;
			margin-bottom: 10px;
		}
		&:hover {
			h3 {
				color: $main-color;
			}
		}
	}
	span {
		font-size: 16px;
		display: block;
		margin-bottom: 6px;
		del {
			margin-right: 10px;
		}
	}
	&:hover{
		.product-img {
			ul {
				transform: scaleY(1);
			}
			&::before {
				transform: scaleY(1);
			}
			.default-btn {
				transform: scaleX(1);
			}
		}
	}
}

/*
product-view-one
=================================================*/
.product-view-one {
	padding-right: 0 !important;
	&.modal.show .modal-dialog {
		margin-top: 25px;
	}
	.product-view-one-image {
		background-color: #eeeeee;
		padding: 30px;
	}
	.modal-dialog {
		max-width: 900px;
		margin: 0 auto;
	}
	.modal-content {
		border: none;
		padding: 40px;
		border-radius: 0;
		button.close {
			position: absolute;
			right: 0;
			top: 0;
			outline: 0;
			opacity: 1;
			color: $heading-color;
			transition: 0.5s;
			line-height: 35px;
			padding: 0;
			margin: 0;
			font-size: 20px;
			width: 35px;
			text-shadow: unset;
			height: 35px;
			&:hover {
				background-color: #ff0000;
				color: $white-color;
			}
		}
		.product-content {
			h3 {
				font-size: 22px;
				margin-bottom: 14px;
				a {
					color: $heading-color;
					&:hover {
						color: $heading-color;
					}
				}
			}
			.price {
				margin-bottom: 10px;
				span {
					display: inline-block;
					font-size: 18px;
					color: $heading-color;
				}
				del {
					display: inline-block;
					font-size: 18px;
					color: #c1c1c1;
					margin-left: 5px;
				}
			}
			.product-review {
				margin-bottom: 10px;
				.rating {
					display: inline-block;
					padding-right: 5px;
					i {
						color: $main-color;
					}
				}
				.rating-count {
					display: inline-block;
					color: $heading-color;
					border-bottom: 1px solid $heading-color;
					line-height: initial;
				}
			}
			.product-info {
				list-style-type: none;
				padding: 0;
				margin-top: 0;
				margin-bottom: 0;
				li {
					font-size: 16px;
					color: $heading-color;
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
					span {
						color: $heading-color;
					}
					a {
						display: inline-block;
						font-weight: 500;
						color: $heading-color;
						&:hover {
							color: $heading-color;
						}
					}
				}
			}
			.product-color-switch {
				margin-top: 10px;
				margin-bottom: 10px;
				h4 {
					font-size: 16px;
					color: $heading-color;
					margin-bottom: 6px;
				}
				ul {
					list-style-type: none;
					margin-bottom: 0;
					margin-top: -4px;
					margin-left: -4px;
					padding-left: 0;
					li {
						display: inline-block;
						margin-left: 4px;
						margin-top: 4px;
						a {
							display: inline-block;
							position: relative;
							border: 1px solid transparent;
							width: 25px;
							height: 25px;
							&::before {
								position: absolute;
								content: "";
								left: 0;
								right: 0;
								top: 0;
								bottom: 0;
								background: #eeeeee;
								margin: 2px;
								transition: 0.5s;
							}
							&:hover {
								border-color: $heading-color;
							}
							&:focus {
								border-color: $heading-color;
							}
						}
						a.color-white {
							&::before {
								background: blue;
							}
						}
						a.color-black {
							&::before {
								background: #222222;
							}
						}
						a.color-green {
							&::before {
								background: green;
							}
						}

						a.color-yellowgreen {
							&::before {
								background: yellowgreen;
							}
						}
						a.color-teal {
							&::before {
								background: teal;
							}
						}
					}
					li.active {
						a {
							border-color: $heading-color;
						}
					}
				}
			}
			.product-add-to-cart {
				.input-counter {
					max-width: 130px;
					min-width: 130px;
					margin-right: 10px;
					text-align: center;
					display: inline-block;
					position: relative;
					span {
						position: absolute;
						top: 0;
						background-color: transparent;
						cursor: pointer;
						color: $body-color;
						width: 40px;
						height: 100%;
						line-height: 50px;
						transition: $transition;
						&:hover {
							color: $heading-color;
						}
					}
					span.minus-btn {
						left: 0;
						transition: $transition;
						&:hover {
							background-color: $main-color;
							color: $white-color;
						}
					}
					span.plus-btn {
						right: 0;
						transition: $transition;
						&:hover {
							background-color: $main-color;
							color: $white-color;
						}
					}
					input {
						height: 48px;
						color: $heading-color;
						outline: 0;
						display: block;
						border: none;
						background-color: #f8f8f8;
						text-align: center;
						width: 100%;
						font-size: 17px;
						font-weight: 600;
						&::placeholder {
							color: $heading-color;
						}
					}
				}
			}
			.share-this-product {
				h3 {
					font-weight: normal;
					font-size: 16px;
					margin-bottom: 8px;
					margin-top: 10px;
				}
				ul {
					li {
						display: inline-block;
						margin-right: 3px;
						a {
							i {
								display: inline-block;
								width: 25px;
								height: 25px;
								line-height: 25px;
								background-color: #eeeeee;
								border-radius: $border-radius;
								text-align: center;
								transition: $transition;
							}
							&:hover {
								i {
									background-color: $main-color;
									color: $white-color;
								}
							}
						}
					}
				}
			}
		}
	}
	.owl-carousel .owl-item img {
		width: unset;
		margin: auto;
	}
	#big .item {
		padding: 30px;
		background-color: $white-color;
		margin-bottom: 30px;
	}
	.owl-item.active.current {
		background-color: $main-color !important;
		padding: 5px;
	}
	&.fade {
		animation: zoomIn 1s linear;
	}
	.owl-next {
		position: absolute;
		top: 50%;
		right: 50px;
		margin: 0 !important;
		border-radius: 0 !important;
		transition: $transition;
		opacity: 0;
		transform: translateY(-25px);
		i {
			background-color: $main-color;
			color: $white-color;
			width: 30px;
			height: 30px;
			line-height: 30px;
			display: inline-block;
			transition: $transition;
			border-radius: 4px;
			font-size: 15px;
		}
	}
	.owl-prev {
		position: absolute;
		top: 50%;
		left: 50px;
		margin: 0 !important;
		border-radius: 0 !important;
		transition: $transition;
		opacity: 0;
		transform: translateY(-25px);
		i {
			background-color: $main-color !important;
			color: $white-color;
			width: 30px;
			height: 30px;
			line-height: 30px;
			display: inline-block;
			transition: $transition;
			font-size: 15px;
			border-radius: 4px;
		}
	}
	&:hover {
		.owl-prev {
			opacity: 1;
			left: 0;
		}
		.owl-next {
			opacity: 1;
			right: 0;
		}
	}
	.owl-theme {
		.owl-nav {
			margin-top: 0 !important;
		}
	}
	.owl-theme .owl-nav [class*=owl-]:hover {
		background-color: transparent;
	}
}

/*
Shopping Cart Area Style
======================================================*/
.shopping-cart-area {
	.cart-controller {
		max-width: 810px;
		margin: auto;
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 30px;
	}
	.product-thumbnail {
		width: 130px;
	}
	.cart-table {
		text-align: center;
		table {
			thead {
				tr {
					th {
						border-bottom: none;
						border: none;
						border-bottom: 1px solid #dedede;
						color: $heading-color;
						padding-top: 0;
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
			tbody {
				tr {
					td {
						vertical-align: middle;
						border: none;
						border-bottom: 1px solid #dedede;
						a {
							color: $heading-color;
							&:hover {
								color: $main-color;
							}
						}
						&.product-subtotal {
							font-size: 15px;
							.remove {
								margin-left: 50px;
								width: 30px;
								height: 30px;
								line-height: 34px;
								display: inline-block;
								background-color: $white-color;
								border-radius: 50%;
								font-size: 20px;
								transition: $transition;
								color: $body-color;
								box-shadow: $box-shadow;
								float: right;
								&:hover {
									background-color: #ff0000;
									color: $white-color;
								}
							}
						}
						&.product-quantity {
							width: 145px;
							.input-counter {
								position: relative;
								input {
									height: 45px;
									color: $heading-color;
									outline: 0;
									display: block;
									border: none;
									background-color: #f8f8f8;
									text-align: center;
									width: 100%;
									font-size: 15px;
									font-weight: 500;
									border-top: 1px solid #dedede;
									border-bottom: 1px solid #dedede;
									&::placeholder {
										color: $heading-color;
									}
								}
								.minus-btn {
									position: absolute;
									top: 0;
									left: 0;
									background-color: transparent;
									cursor: pointer;
									color: $heading-color;
									width: 40px;
									height: 100%;
									line-height: 48px;
									transition: $transition;
									border: 1px solid #dedede;
									&:hover {
										color: $white-color;
										background-color: $main-color;
									}
								}
								.plus-btn {
									position: absolute;
									top: 0;
									right: 0;
									background-color: transparent;
									cursor: pointer;
									color: $heading-color;
									width: 40px;
									height: 100%;
									line-height: 48px;
									transition: $transition;
									border: 1px solid #dedede;
									&:hover {
										color: $white-color;
										background-color: $main-color;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.coupon-cart {
		margin-top: 20px;
		.form-group {
			position: relative;
			.form-control {
				border: 1px solid #dedede;
				&:focus {
					border-color: $main-color;
				}
			}
			.default-btn {
				position: absolute;
				top: 0;
				right: 0;
				height: 50px;
			}
		}
		.default-btn {
			&.update {
				background-color: transparent;
				border-color: $heading-color;
				color: $heading-color;
				&:hover {
					background-color: $main-color;
					border-color: $main-color;
					color: $white-color;
				}
			}
		}
	}
	.cart-totals {
		padding: 30px;
		box-shadow: $box-shadow;
		background-color: $white-color;
		max-width: 390px;
		margin: 40px auto 0;
		h3 {
			margin-bottom: 25px;
			position: relative;
			padding-bottom: 10px;
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 150px;
				height: 2px;
				background-color: $main-color;
			}
		}
		ul {
			padding: 0;
			margin: 0 0 25px;
			list-style-type: none;
			li {
				color: $body-color;
				position: relative;
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 1px dashed #dedede;
				&:last-child {
					padding-bottom: 0;
					border-bottom: none;
					margin-bottom: 0;
				}
				span {
					float: right;
					color: #666666;
					font-weight: normal;
				}
			}
		}
		.default-btn {
			display: block;
		}
	}
}

/*
Checkout Area Style
=====================================================*/
.checkout-area {
	.billing-details {
		background-color: $white-color;
		box-shadow: $box-shadow;
		padding: 30px;
		h3 {
			margin-bottom: 20px;
			font-size: 30px;
		}
		.form-group {
			margin-bottom: 20px;
			label {
				display: block;
				color: $body-color;
				margin-bottom: 10px;
				font-weight: 600;
				font-size: 14px;
				.required {
					color: $body-color;
				}
			}
			.nice-select {
				float: unset;
				line-height: 45px;
				color: $body-color;
				padding-top: 0;
				padding-bottom: 0;
				.list {
					background-color: $white-color;
					box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
					border-radius: 0;
					margin-top: 0;
					width: 100%;
					padding-top: 10px;
					padding-bottom: 10px;
					.option {
						transition: 0.5s;
						padding-left: 20px;
						padding-right: 20px;
						&:hover {
							background-color: $main-color !important;
							color: $white-color;
						}
					}
					.option.selected {
						background-color: transparent;
					}
				}
				&:after {
					right: 20px;
				}
			}
			.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
				background-color: $main-color !important;
				color: $white-color !important;
			}
			textarea {
				padding-top: 13px;
			}
		}
		.form-check {
			margin-bottom: 20px;
			.form-check-label {
				color: $body-color;
				font-weight: 600;
				font-size: 14px;
			}
			label {
				position: relative;
				top: 1px;
			}
		}
		.col-lg-12 {
			&:last-child {
				.form-group {
					margin-bottom: 0;
				}
			}
		}
	}
	.cart-totals {
		margin-bottom: 30px;
		box-shadow: $box-shadow;
		padding: 30px;
		background-color: $white-color;
		h3 {
			position: relative;
			margin-bottom: 25px;
			padding-bottom: 10px;
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 150px;
				height: 2px;
				background-color: $main-color;
			}
		}
		ul {
			li {
				color: $body-color;
				position: relative;
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 1px dashed #dedede;
				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
					margin-bottom: 0;
				}
				span {
					float: right;
					color: #666666;
					font-weight: normal;
				}
			}
		}
	}
	.faq-accordion {
		margin-bottom: 30px;
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 30px;
		margin-bottom: 0;
		h3 {
			position: relative;
			margin-bottom: 25px;
			padding-bottom: 10px;
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 150px;
				height: 2px;
				background-color: $main-color;
			}
		}
		.accordion {
			.accordion-content {
				border-top: none;
				padding: 0;
			}
			.accordion-title {
				padding: 0;
				position: relative;
				padding-left: 25px;
				background-color: transparent;
				border: none;
				margin-bottom: 10px;
				color: $heading-color;
				font-weight: 500;
				&.active {
					&::before {
						background-color: $main-color;
					}
					&::after {
						background-color: $main-color;
					}
				}
				&::before {
					content: "";
					position: absolute;
					top: 4px;
					left: 0;
					width: 15px;
					height: 15px;
					background-color: $heading-color;
					border-radius: 50%;
				}
				&::after {
					content: "";
					position: absolute;
					top: 6px;
					left: 2px;
					width: 11px;
					height: 11px;
					background-color: $white-color;
					border-radius: 50%;
					border-radius: 50%;
				}
				&:hover {
					color: $main-color;
				}
			}
			.accordion-item {
				background-color: transparent;
				box-shadow: none;
				margin-bottom: 15px;
			}
		}
		.form-check {
			font-size: 14px;
			label {
				margin-bottom: 5px;
				a {
					color: $main-color;
					&:hover {
						color: $main-color;
					}
				}
			}
		}
	}
}

/*
Wishlist Area Style
=====================================================*/
.wishlist {
	max-width: 1080px;
	margin: auto;
	background-color: $white-color;
	box-shadow: $box-shadow;
	padding: 30px;
	.default-btn {
		float: right;
		margin-top: -11px;
		color: $white-color !important;
		position: relative;
		top: 6px;
		&:hover {
			color: $main-color !important;
		}
	}
	.table-responsive>.table-bordered {
		margin-bottom: 0;
	}
}

/*
Shop Details Area Style
=====================================================*/
.product-details-area {
	.product-details-desc {
		h3 {
			margin-bottom: 20px;
			font-size: 25px;
		}
		p {
			margin-bottom: 0;
		}
		.price {
			margin-bottom: 15px;
			padding-bottom: 15px;
			color: $body-family;
			font-size: 20px;
			font-weight: 500;
			border-bottom: 1px solid #eeeeee;
			span {
				padding: 0 5px;
			}
			del {
				color: $main-color;
				margin-right: 10px;
			}
		}
		.product-add-to-cart {
			margin-bottom: 20px;
			border-top: 1px solid #eeeeee;
			padding-top: 20px;
			h3 {
				display: inline-block;
				margin-right: 10px;
				font-size: 17px;
			}
			margin-top: 20px;
			.input-counter {
				max-width: 130px;
				min-width: 130px;
				margin-right: 10px;
				text-align: center;
				display: inline-block;
				position: relative;
				border: 1px solid #cfcfcf;
				span {
					position: absolute;
					top: 0;
					background-color: transparent;
					cursor: pointer;
					color: #d0d0d0;
					width: 40px;
					height: 100%;
					line-height: 34px;
					transition: 0.5s;
					&:hover {
						color: $main-color;
					}
				}
				span.minus-btn {
					left: 0;
					border-right: 1px solid #cfcfcf;
				}
				span.plus-btn {
					right: 0;
					border-left: 1px solid #cfcfcf;
				}
				input {
					height: 30px;
					color: #333333;
					outline: 0;
					display: block;
					border: none;
					background-color: #f8f8f8;
					text-align: center;
					width: 100%;
					&::placeholder {
						color: #333333;
					}
				}
			}
		}
		.social-wrap {
			margin-top: 20px;
			li {
				display: inline-block;
				padding-right: 10px;
				font-size: 17px;
				font-weight: 600;
				color: $heading-color;
				span {
					display: inline-block;
					margin-right: 45px;
				}
				a {
					i {
						font-size: 20px;
						line-height: 1;
						color: $heading-color;
						transition: $transition;
					}
					&:hover {
						i {
							color: $main-color;
						}
					}
				}
			}
		}
		.default-btn {
			margin-bottom: 0 !important;
			i {
				position: relative;
				top: -1px;
				margin-right: 5px;
			}
		}
		.category {
			font-size: 17px;
			font-weight: 600;
			margin-top: 15px;
			span {
				font-weight: normal;
			}
		}
		.product-review {
			position: relative;
			margin-bottom: 10px;
			.rating {
				i {
					color: #ffba0a;
				}
			}
			.rating-count {
				position: absolute;
				top: 0;
				left: 110px;
			}
		}
	}
	.product-details-image {
		text-align: center;
		background-color: #f7f7f7;
	}
	.tab {
		.tabs_item {
			display: none;
			&:first-child {
				display: block;
			}
		}
	}
	.products-details-tab {
		margin-top: 50px;
		.tabs {
			list-style-type: none;
			padding-left: 0;
			text-align: center;
			border-bottom: 1px solid #eaedff;
			li {
				display: inline-block;
				line-height: initial;
				margin-right: 15px;
				margin-left: 15px;
				margin-bottom: 20px;
				a {
					display: inline-block;
					position: relative;
					color: #333333;
					border-bottom: none;
					line-height: 1;
					font-size: 16px;
					font-weight: 500;
					&:hover {
						color: $main-color;
					}
				}
			}
			li.current {
				a {
					color: $main-color;
				}
			}
		}
		.tab_content {
			padding: 30px;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
			.tabs_item {
				.products-details-tab-content {
					p {
						margin-bottom: 20px;
						&:last-child {
							margin-bottom: 0;
						}
					}
					.additional-information {
						padding-left: 0;
						margin-bottom: 0;
						list-style-type: none;
						text-align: left;
						li {
							border: 1px solid #eaedff;
							border-bottom: none;
							padding: 10px 15px;
							color: #57647c;
							&:last-child {
								border-bottom: 1px solid #eaedff;
							}
							span {
								display: inline-block;
								width: 30%;
								color: $heading-color;
							}
						}
					}
					h3 {
						margin-bottom: 15px;
					}
					.product-review-form {
						.review-title {
							position: relative;
							.rating {
								display: inline-block;
								.fas.fa-star {
									color: #ffba0a;
								}
								i {
									color: #ffba0a;
									font-size: 14px;
								}
							}
							p {
								margin-bottom: 0;
								display: inline-block;
								padding-left: 5px;
								line-height: initial;
							}
							.btn {
								position: absolute;
								right: 0;
								bottom: 10px;
								padding: 10px 11px;
								font-size: 11px;
								&:focus {
									border: none !important;
									box-shadow: none !important;
									border: 4px;
									background-color: $main-color;
								}
							}
						}
						.review-comments {
							margin-top: 35px;
							.review-item {
								margin-top: 30px;
								position: relative;
								padding-right: 200px;
								border-top: 1px dashed #eaedff;
								padding-top: 30px;
								.rating {
									.fas.fa-star {
										color: #ffba0a;
									}
									i {
										font-size: 14px;
										color: #ffba0a;
									}
								}
								h3 {
									margin-top: 10px;
									margin-bottom: 10px;
								}
								span {
									margin-bottom: 10px;
									font-size: 13px;
									display: block;
								}
								p {
									margin-bottom: 0;
								}
								.review-report-link {
									position: absolute;
									right: 0;
									color: #57647c;
									top: 40px;
									text-decoration: underline;
									&:hover {
										color: #ff4800;
									}
								}
							}
						}
						.review-form {
							margin-top: 30px;
							form {
								.form-group {
									margin-bottom: 20px;
									label {
										color: #444444;
									}
									textarea {
										padding-top: 15px;
									}
									.form-control {
										font-size: 14px;
									}
								}
								.btn {
									&:focus {
										box-shadow: none;
										border: none;
										border-radius: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
Blog Details Area Style
======================================================*/
.blog-details-content {
	.blog-top-content {
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 20px;
		.blog-details-img {
			margin-bottom: 30px;
		}
		.news-content {
			h3 {
				transition: $transition;
				margin-bottom: 20px;
				font-size: 25px;
			}
			.admin {
				padding-bottom: 24px;
				line-height: 1;
				li {
					display: inline-block;
					font-size: 16px;
					margin-right: 30px;
					&:last-child {
						margin-right: 0;
					}
					i {
						color: $main-color;
						font-size: 18px;
						position: relative;
						top: 1px;
					}
				}
			}
		}
		blockquote {
			position: relative;
			margin-top: 30px;
			margin-bottom: 30px;
			p {
				font-weight: 500;
				font-size: 18px;
				color: $heading-color;
				text-align: center;
				padding-left: 150px;
			}
			i {
				font-size: 60px;
				color: $main-color;
				position: absolute;
				top: 0;
				left: 0;
				line-height: 1;
			}
		}
	}
	.news-content-2 {
		margin-bottom: 30px;
		h3 {
			font-size: 24px;
		}
	}
	.single-blog-post-img {
		margin-bottom: 30px;
	}
	.social {
		background-color: $white-color;
		box-shadow: $box-shadow;
		padding: 20px;
		margin-top: 30px;
		margin-bottom: 30px;
		span {
			margin-right: 10px;
			font-weight: 500;
		}
		ul {
			display: inline-block;
			float: right;
			li {
				display: inline-block;
				a {
					i {
						font-size: 18px;
						margin-right: 10px;
						width: 25px;
						height: 25px;
						line-height: 25px;
						border-radius: 50%;
						border: 1px solid $body-color;
						text-align: center;
						transition: $transition;
					}
					&:hover {
						i {
							background-color: $main-color;
							border-color: $main-color;
							color: $white-color;
						}
					}
				}
				&:last-child {
					a {
						margin-right: 0;
					}
				}
			}
		}
	}
	.comments {
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 20px;
		margin-bottom: 35px;
		h3 {
			margin-bottom: 30px;
			padding-bottom: 10px;
			font-size: 24px;
			position: relative;
			display: inline-block;
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $main-color;
			}
		}
		ul {
			li {
				position: relative;
				padding-left: 100px;
				margin-bottom: 30px;
				&:last-child {
					margin-bottom: 0;
				}
				img {
					margin-bottom: 20px;
					position: absolute;
					left: 0;
					top: 0;
				}
				h3 {
					margin-bottom: 0;
					padding-bottom: 0;
					font-size: 16px;
					padding-left: 0;
					&::before {
						display: none;
					}
				}
				span {
					display: block;
					margin-bottom: 15px;
				}
				a {
					font-size: 14px;
					font-weight: 600;
					&:hover {
						letter-spacing: 1px;
					}
					i {
						margin-right: 5px;
					}
				}
			}
		}
	}
	.leave-reply {
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 20px;
		h3 {
			margin-bottom: 30px;
			font-size: 24px;
			position: relative;
			padding-bottom: 10px;
			display: inline-block;
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $main-color;
			}
		}
		p {
			margin-bottom: 30px;
		}
		.form-group {
			margin-bottom: 25px;
			#chb1 {
				margin-right: 5px;
			}
			label {
				margin-bottom: 10px;
			}
		}
		input[type=checkbox], input[type=radio] {
			margin-right: 5px;
			position: relative;
			top: 1px;
		}
	}
}
.sidebar-widget {
	margin-bottom: 35px;
	position: relative;
	padding: 20px;
	box-shadow: $box-shadow;
	background-color: $white-color;
	h3 {
		font-size: 20px;
		margin-bottom: 20px;
		position: relative;
		margin-top: -5px;
		padding-bottom: 10px;
		display: inline-block;
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $main-color;
		}
	}
	&.search {
		.search-form {
			.search-button {
				position: absolute;
				width: 50px;
				height: 50px;
				line-height: 50px;
				background-color: $main-color;
				top: 20px;
				right: 20px;
				font-size: 20px;
				color: $white-color;
				transition: $transition;
				&:hover {
					background-color: $heading-color;
				}
			}
		}
	}
	&.latest-news {
		ul {
			li {
				margin-bottom: 20px;
				position: relative;
				padding-left: 20px;
				&::before {
					content: "";
					position: absolute;
					top: 7px;
					left: 0;
					width: 10px;
					height: 10px;
					background-color: $main-color;
				}
				&:last-child {
					margin-bottom: 0;
				}
				a {
					font-size: 17px;
					color: $heading-color;
					margin-bottom: 10px;
				}
				span {
					display: block;
					font-size: 14px;
				}
			}
		}
	}
	&.categories {
		ul {
			li {
				position: relative;
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid #e7e7e7;
				font-size: 17px;
				span {
					float: right;
					display: inline-block;
				}
				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: none;
				}
				&:first-child {
					&::before {
						display: none;
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&.tags {
		ul {
			li {
				display: inline-block;
				margin-bottom: 10px;
				margin-right: 10px;
				a {
					border: 1px solid #eeeeee;
					padding: 8px 12px;
					&:hover {
						transform: translateY(-2px);
						background-color: $main-color;
						color: $white-color;
						border-color: $main-color;
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&.gallery {
		ul {
			padding-left: 0;
			list-style-type: none;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0;
			margin-left: -5px;
			margin-right: -5px;
			margin-top: -10px;
			li {
				flex: 0 0 33.333333%;
				max-width: 33.333333%;
				text-align: center;
				padding-left: 5px;
				padding-right: 5px;
				padding-top: 10px;
				img {
					width: 100%;
				}
			}
		}
	}
	&.appointment-box {
		position: unset;
		padding: 0;
		box-shadow: none;
	}
}

/*
Services Details Area Style
======================================================*/
.services-details-content {
	.services-top-content {
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 20px;
		.services-details-img {
			margin-bottom: 30px;
		}
		.news-content {
			h3 {
				transition: $transition;
				margin-bottom: 15px;
				font-size: 25px;
			}
		}
	}
	.news-content-2 {
		margin-bottom: 30px;
		margin-top: 20px;
		h3 {
			font-size: 25px;
			margin-bottom: 15px;
		}
	}
	.news-content-3 {
		margin-top: 20px;
		h3 {
			font-size: 25px;
			margin-bottom: 15px;
		}
		ul {
			li {
				position: relative;
				padding-left: 30px;
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
				i {
					position: absolute;
					top: 0;
					left: 0;
					font-size: 25px;
					line-height: 1;
					color: $main-color;
				}
			}
		}
	}
	.single-services-post-img {
		margin-bottom: 30px;
	}
}

/*
Portfolio Details Area Style
======================================================*/
.portfolio-details-content {
	max-width: 810px;
	margin: auto;
	.portfolio-top-content {
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 20px;
		.portfolio-details-img {
			margin-bottom: 30px;
		}
		.news-content {
			h3 {
				transition: $transition;
				margin-bottom: 15px;
				font-size: 25px;
			}
		}
	}
	.news-content-2 {
		margin-bottom: 30px;
		margin-top: 20px;
		h3 {
			font-size: 25px;
			margin-bottom: 15px;
		}
	}
	.news-content-3 {
		margin-top: 20px;
		h3 {
			font-size: 25px;
			margin-bottom: 15px;
		}
		ul {
			li {
				position: relative;
				padding-left: 30px;
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
				i {
					position: absolute;
					top: 0;
					left: 0;
					font-size: 25px;
					line-height: 1;
					color: $main-color;
				}
			}
		}
	}
	.single-portfolio-post-img {
		margin-bottom: 30px;
	}
}

/*
Department Details Area Style
======================================================*/
.department-details-content {
	.department-top-content {
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 20px;
		.department-details-img {
			margin-bottom: 30px;
		}
		.news-content {
			h3 {
				transition: $transition;
				margin-bottom: 15px;
				font-size: 25px;
			}
		}
	}
	.news-content-2 {
		margin-bottom: 30px;
		margin-top: 20px;
		h3 {
			font-size: 25px;
			margin-bottom: 15px;
		}
	}
	.news-content-3 {
		margin-top: 20px;
		h3 {
			font-size: 25px;
			margin-bottom: 15px;
		}
		ul {
			li {
				position: relative;
				padding-left: 30px;
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
				i {
					position: absolute;
					top: 0;
					left: 0;
					font-size: 25px;
					line-height: 1;
					color: $main-color;
				}
			}
		}
	}
	.single-department-post-img {
		margin-bottom: 30px;
	}
}

/*
Doctors Details Area Style
=====================================================*/
.doctors-sidebar {
	.doctors-img {
		text-align: center;
		margin-bottom: 30px;
		img {
			border-top: 3px solid $main-color;
			border-left: 3px solid $main-color;
			border-right: 3px solid $main-color;
		}
		ul {
			line-height: 1;
			box-shadow: $box-shadow;
			background-color: $white-color;
			padding: 30px;
			li {
				display: inline-block;
				margin: 0 5px;
				a {
					width: 30px;
					height: 30px;
					line-height: 38px;
					background-color: $main-color;
					color: $white-color;
					border-radius: 4px;
					text-align: center;
					i {
						font-size: 20px;
					}
					&:hover {
						transform: translateY(-3px);
					}
				}
			}
		}
	}
	.availability {
		box-shadow: $white-color;
		background-color: $white-color;
		padding: 30px;
		margin-bottom: 30px;
		h3 {
			margin-bottom: 20px;
			i {
				margin-right: 5px;
				position: relative;
				top: 1px;
			}
		}
		ul {
			li {
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
				margin-bottom: 10px;
				span {
					float: right;
				}
				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}
		}
	}
	.appointment-box {
		position: unset;
	}
}
.doctors-details {
	.borders {
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 20px;
		margin-bottom: 20px;
		margin-left: 1px;
		margin-right: 1px;
	}
	.doctors-history {
		padding: 30px;
		box-shadow: $box-shadow;
		h2 {
			font-size: 40px;
		}
		span {
			font-weight: 600;
			color: $main-color;
			font-size: 20px;
			display: block;
			margin-bottom: 15px;
		}
		p {
			margin-bottom: 30px;
		}
	}
	.left-title {
		h3 {
			font-size: 17px;
		}
	}
	.right-title {
		ul {
			li {
				position: relative;
				padding-left: 25px;
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid #eeeeee;
				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: none;
				}
				i {
					position: absolute;
					top: 3px;
					left: 0;
				}
			}
		}
	}
}

/*====================================================
OTHERS STYLE AREA
======================================================*/

/*
Preloader Area Style*/
.preloader {
	position: fixed;
	left: 0;
	width: 0;
	height: 100%;
	width: 100%;
	text-align: center;
	z-index: 9999999;
	transition: $transition;
	.loader {
		position: absolute;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		display: inline-block;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 45%;
		transform: translateY(-45%);
		transition: $transition;
		.loader-outter {
			position: absolute;
			border: 4px solid $white-color;
			border-left-color: transparent;
			border-bottom: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
		}
		.loader-inner {
			position: absolute;
			border: 4px solid $white-color;
			border-radius: 50%;
			width: 60px;
			height: 60px;
			left: calc(40% - 21px);
			top: calc(40% - 21px);
			border-right: 0;
			border-top-color: transparent;
			animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
		}
		.indicator {
			position: absolute;
			right: 0;
			left: 0;
			top: 50%;
			transform: translateY(-50%) scale(1.5);
			svg {
				polyline {
					fill: none;
					stroke-width: 2;
					stroke-linecap: round;
					stroke-linejoin: round;
				}
				polyline#back {
					stroke: #ffffff;
				}
				polyline#front {
					stroke: $main-color;
					stroke-dasharray: 12, 36;
					stroke-dashoffset: 48;
					animation: dash 1s linear infinite;
				}
			}
		}
	}
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 60%;
		z-index: -1;
		background: $main-color;
		transition: $transition;
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 60%;
		z-index: -1;
		background: $main-color;
		transition: $transition;
		left: auto;
		right: 0;
	}
	&.preloader-deactivate {
		visibility: hidden;
		&::after {
			width: 0;
		}
		&::before {
			width: 0;
		}
		.loader {
			opacity: 0;
			visibility: hidden;
		}
	}
}
@keyframes loader-outter {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes loader-inner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
@keyframes dash {
	62.5% {
	  opacity: 0;
	}
	to {
	  stroke-dashoffset: 0;
	}
}

/*
Go Top Style*/
.go-top {
	position: fixed;
	cursor: pointer;
	top: 82%;
	right: -10%;
	background-color: $main-color;
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 42px;
	line-height: 42px;
	opacity: 0;
	visibility: hidden;
	transition: .9s;
	i {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		margin: 0 auto;
		color: $white-color;
		transition: 0.5s;
		font-size: 20px;
		&:last-child {
			opacity: 0;
			visibility: hidden;
			top: 60%;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: $main-color;
		opacity: 0;
		visibility: hidden;
		transition: 0.5s;
	}
	&:hover {
		color: $white-color;
		background-color: $main-color;
		&::before {
			opacity: 1;
			visibility: visible;
		}
		i {
			&:first-child {
				opacity: 0;
				top: 0;
				visibility: hidden;
			}
			&:last-child {
				opacity: 1;
				visibility: visible;
				top: 50%;
			}
		}
	}
	&:focus {
		color: $white-color;
		&::before {
			opacity: 1;
			visibility: visible;
		}
		i {
			&:first-child {
				opacity: 0;
				top: 0;
				visibility: hidden;
			}
			&:last-child {
				opacity: 1;
				visibility: visible;
				top: 50%;
			}
		}
	}
}
.go-top.active {
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	right: 3%;
	top: 82%;
}

/*
Video wave Style*/
.video-btn {
	display: inline-block;
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	border-radius: 0;
	color: $main-color;
	position: relative;
	top: 3px;
	z-index: 1;
	background-color: rgba(238, 50, 50, 0.9);
	border-radius: 50%;
	transition: $transition;
	i {
		font-size: 25px;
		color: $white-color;
		position: absolute;
		top: 1px;
    	left: 4px;
		justify-content: center;
		align-items: center;
		display: flex;
		width: 100%;
		height: 100%;
	}
	&::after, &::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		bottom: 0;
		left: 0;
		border-radius: 0;
		border-radius: 50%;
		transition: $transition;
		animation: ripple 1.6s ease-out infinite;
		background-color: rgba(238, 50, 50, 0.9);
	}
	&:hover {
		&::before, &::after {
			background-color: rgba(238, 50, 50, 0.9);
		}
	}
}
@keyframes ripple {
	0%, 35% {
		transform: scale(0);
	  	opacity: 1;
	}
	50% {
		transform: scale(1.5);
	  	opacity: 0.8;
	}
	100% {
	  	opacity: 0;
		transform: scale(2);
	}
}

/*
Nice select Area Style*/
.nice-select .list {
	width: 100%;
	border-radius: 0;
}
.nice-select {
	.option {
		&:hover {
			background-color: $main-color;
			color: $white-color;
		}
	}
	.option.selected.focus {
		color: $main-color;
	}
	.current {
		font-size: 16px;
	}
}

/*
Page-navigation Area Style*/
.pagination-area {
	margin-top: 10px;
	text-align: center;
	.page-numbers {
		width: 40px;
		height: 40px;
		line-height: 40px;
		color: $heading-color;
		text-align: center;
		display: inline-block;
		position: relative;
		margin-left: 3px;
		margin-right: 3px;
		font-size: 17px;
		background-color: #f5f6fa;
		border: 1px solid #d7d7d7;
		&:hover {
			color: $white-color;
			border-color: $main-color;
			background-color: $main-color;
		}
		i {
			position: relative;
			font-size: 25px;
			top: 5px;
		}
	}
	.page-numbers.current {
		color: $white-color;
		border-color: $main-color;
		background-color: $main-color;
	}
}

/*
product-view-one
=================================================*/
.product-view-one {
	padding-right: 0 !important;
	&.modal.show .modal-dialog {
		margin-top: 25px;
	}
	.product-view-one-image {
		background-color: #eeeeee;
		padding: 30px;
	}
	.modal-dialog {
		max-width: 900px;
		margin: 0 auto;
	}
	.modal-content {
		border: none;
		padding: 40px;
		border-radius: 0;
		button.close {
			position: absolute;
			right: 0;
			top: 0;
			outline: 0;
			opacity: 1;
			color: $heading-color;
			transition: 0.5s;
			line-height: 35px;
			padding: 0;
			margin: 0;
			font-size: 20px;
			width: 35px;
			text-shadow: unset;
			height: 35px;
			&:hover {
				background-color: #ff0000;
				color: $white-color;
			}
		}
		.product-content {
			h3 {
				font-size: 22px;
				margin-bottom: 14px;
				a {
					color: $heading-color;
					&:hover {
						color: $heading-color;
					}
				}
			}
			.price {
				margin-bottom: 10px;
				span {
					display: inline-block;
					font-size: 18px;
					color: $heading-color;
				}
				del {
					display: inline-block;
					font-size: 18px;
					color: #c1c1c1;
					margin-left: 5px;
				}
			}
			.product-review {
				margin-bottom: 10px;
				.rating {
					display: inline-block;
					padding-right: 5px;
					i {
						color: $main-color;
					}
				}
				.rating-count {
					display: inline-block;
					color: $heading-color;
					border-bottom: 1px solid $heading-color;
					line-height: initial;
				}
			}
			.product-info {
				list-style-type: none;
				padding: 0;
				margin-top: 0;
				margin-bottom: 0;
				li {
					font-size: 16px;
					color: $heading-color;
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
					span {
						color: $heading-color;
					}
					a {
						display: inline-block;
						font-weight: 500;
						color: $heading-color;
						&:hover {
							color: $heading-color;
						}
					}
				}
			}
			.product-color-switch {
				margin-top: 10px;
				margin-bottom: 10px;
				h4 {
					font-size: 16px;
					color: $heading-color;
					margin-bottom: 6px;
				}
				ul {
					list-style-type: none;
					margin-bottom: 0;
					margin-top: -4px;
					margin-left: -4px;
					padding-left: 0;
					li {
						display: inline-block;
						margin-left: 4px;
						margin-top: 4px;
						a {
							display: inline-block;
							position: relative;
							border: 1px solid transparent;
							width: 25px;
							height: 25px;
							&::before {
								position: absolute;
								content: "";
								left: 0;
								right: 0;
								top: 0;
								bottom: 0;
								background: #eeeeee;
								margin: 2px;
								transition: 0.5s;
							}
							&:hover {
								border-color: $heading-color;
							}
							&:focus {
								border-color: $heading-color;
							}
						}
						a.color-white {
							&::before {
								background: blue;
							}
						}
						a.color-black {
							&::before {
								background: #222222;
							}
						}
						a.color-green {
							&::before {
								background: green;
							}
						}

						a.color-yellowgreen {
							&::before {
								background: yellowgreen;
							}
						}
						a.color-teal {
							&::before {
								background: teal;
							}
						}
					}
					li.active {
						a {
							border-color: $heading-color;
						}
					}
				}
			}
			.product-add-to-cart {
				.input-counter {
					max-width: 130px;
					min-width: 130px;
					margin-right: 10px;
					text-align: center;
					display: inline-block;
					position: relative;
					span {
						position: absolute;
						top: 0;
						background-color: transparent;
						cursor: pointer;
						color: $body-color;
						width: 40px;
						height: 100%;
						line-height: 50px;
						transition: $transition;
						&:hover {
							color: $heading-color;
						}
					}
					span.minus-btn {
						left: 0;
						transition: $transition;
						&:hover {
							background-color: $main-color;
							color: $white-color;
						}
					}
					span.plus-btn {
						right: 0;
						transition: $transition;
						&:hover {
							background-color: $main-color;
							color: $white-color;
						}
					}
					input {
						height: 48px;
						color: $heading-color;
						outline: 0;
						display: block;
						border: none;
						background-color: #f8f8f8;
						text-align: center;
						width: 100%;
						font-size: 17px;
						font-weight: 600;
						&::placeholder {
							color: $heading-color;
						}
					}
				}
			}
			.share-this-product {
				h3 {
					font-weight: normal;
					font-size: 16px;
					margin-bottom: 8px;
					margin-top: 10px;
				}
				ul {
					li {
						display: inline-block;
						margin-right: 3px;
						a {
							i {
								display: inline-block;
								width: 25px;
								height: 25px;
								line-height: 25px;
								background-color: #eeeeee;
								border-radius: $border-radius;
								text-align: center;
								transition: $transition;
							}
							&:hover {
								i {
									background-color: $main-color;
									color: $white-color;
								}
							}
						}
					}
				}
			}
		}
	}
	.owl-carousel .owl-item img {
		width: unset;
		margin: auto;
	}
	#big .item {
		padding: 30px;
		background-color: $white-color;
		margin-bottom: 30px;
	}
	.owl-item.active.current {
		background-color: $white-color;
		padding: 5px;
	}
	&.fade {
		animation: zoomIn 1s linear;
	}
	.owl-next {
		position: absolute;
		top: 50%;
		right: 50px;
		margin: 0 !important;
		border-radius: 0 !important;
		transition: $transition;
		opacity: 0;
		transform: translateY(-25px);
		i {
			background-color: $main-color;
			color: $white-color;
			width: 30px;
			height: 30px;
			line-height: 30px;
			display: inline-block;
			transition: $transition;
			border-radius: 4px;
			font-size: 15px;
		}
	}
	.owl-prev {
		position: absolute;
		top: 50%;
		left: 50px;
		margin: 0 !important;
		border-radius: 0 !important;
		transition: $transition;
		opacity: 0;
		transform: translateY(-25px);
		i {
			background-color: $main-color !important;
			color: $white-color;
			width: 30px;
			height: 30px;
			line-height: 30px;
			display: inline-block;
			transition: $transition;
			font-size: 15px;
			border-radius: 4px;
		}
	}
	&:hover {
		.owl-prev {
			opacity: 1;
			left: 0;
		}
		.owl-next {
			opacity: 1;
			right: 0;
		}
	}
	.owl-theme {
		.owl-nav {
			margin-top: 0 !important;
		}
	}
	.owl-theme .owl-nav [class*=owl-]:hover {
		background-color: transparent;
	}
}

.shopping-cart-area {
	.cart-table {
		table {
			tbody {
				tr {
					td {
						white-space: nowrap;
						&:first-child {
							text-align: left;
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}
