/* Max width 767px */
@media only screen and (max-width: 767px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    h3 {
        font-size: 20px;
    }
    .default-btn {
        font-size: 15px;
        padding: 12px 20px;
    }
    .section-title {
        margin-bottom: 30px;
        text-align: center !important;
        span {
            font-size: 14px;
        }
        h2 {
            font-size: 25px;
        }
    }
    .section-btn {
        position: unset;
        margin-bottom: 40px;
        text-align: center;
    }
    .top-header {
        .header-left-content {
            margin-bottom: 20px;
            text-align: center;
        }
        .header-right-content {
            float: unset;
            text-align: center;
            li{
                padding-left: 0;
                i {
                    display: none;
                }
                span {
                    font-size: 14px;
                }
                a {
                    font-size: 14px;
                }
                &::after {
                    display: none;
                }
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .banner-area {
        height: 100%;
        padding-top: 135px;
        padding-bottom: 70px;
    }
    .banner-content {
        text-align: center;
        h1 {
            font-size: 35px;
        }
    }
    .single-feature {
        padding-left: 0;
        span {
            font-size: 16px;
        }
        &.active {
            margin-top: 30px;
            margin-bottom: 30px;
            &::before {
                display: none;
            }
            img {
                width: 36px;
                top: -5px;
            }
            span {
                color: #ffffff;
                padding-left: 65px;
                top: 4px;
            }
        }
    }
    .partner-area {
        .owl-theme {
            .owl-nav {
                .owl-next {
                    right: 0;
                }
                .owl-prev {
                    left: 0;
                }
            }
        }
    }
    .choose-us-content {
        text-align: center;
    }
    .choose-us-img {
        margin-bottom: 30px;
        text-align: center;
    }
    .appointment-here-form {
        background-image: none;
        text-align: center;
        padding: 20px;
        .default-btn {
            margin-bottom: 0;
        }
    }
    .single-services {
        padding: 20px;
        .services-icon {
            width: 80px;
            height: 80px;
        }
    }
    .testimonial-img {
        margin-bottom: 30px;
    }
    .testimonial-content {
        text-align: center;
        i {
            margin-bottom: 5px;
        }
        p {
            font-size: 17px;
        }
    }
    .testimonial-area {
        .owl-carousel .owl-item img {
            margin: auto;
        }
        .owl-theme .owl-dots {
            margin-top: 30px !important;
        }
    }
    .single-doctors {
        margin-top: 0 !important;
    }
    .doctors-area {
        .owl-theme {
            .owl-nav {
                position: unset;
                margin-bottom: 30px;
            }
        }
    }
    .single-blog-post {
        .blog-content {
            padding: 20px;
        }
    }
    .single-blog-post {
        .blog-content {
            ul {
                margin-top: 10px;
            }
        }
    }
    .subscribe-bg {
        background-image: none;
        padding: 50px 20px;
        padding-bottom: 20px;
        .newsletter-form {
            .default-btn {
                position: unset;
                height: 50px;
                margin: 30px auto 0;
                display: table;
            }
        }
    }
    .subscribe-content {
        text-align: center;
        padding-left: 0;
        img {
            position: unset;
            margin-bottom: 30px;
        }
    }
    .single-footer-widget {
        h3 {
            margin-bottom: 15px
        }
        img {
            margin-bottom: 20px;
        }
    }
    .copy-right-area {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .navbar-area {
        .others-option {
            .option-item {
                .search-overlay {
                    &.search-popup {
                        width: 220px;
                    }
                }
            }
        }
    }
    .choose-us-content-two {
        h2 {
            font-size: 25px;
        }
    }
    .address-and-week {
        position: unset;
        max-width: 100%;
    }
    .choose-us-content-two {
        margin-bottom: 30px;
    }
    .choose-us-img-two {
        img {
            margin-bottom: 30px;
        }
        &::before {
            display: none;
        }
    }
    .single-counter {
        padding-left: 0;
        text-align: center;
        i {
            font-size: 40px;
            position: unset;
            margin-bottom: 15px;
            display: block;
        }
        h2 {
            font-size: 30px;
        }
        h3 {
            font-size: 14px;
        }
    }
    .single-blog-post {
        &.mb-0 {
            margin-bottom: 30px !important;
        }
    }
    .doctors-img {
        img {
            width: 100%;
        }
    }
    .services-area {
        &.services-area-two {
            .owl-carousel {
                .owl-item {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .banner-area {
        &.banner-area-three {
            height: 100%;
            .banner-content {
                margin-left: auto;
                margin-bottom: 30px;
                h1 {
                    font-size: 35px;
                }
            }
        }
    }
    .appointment-box {
        position: unset;
        margin-bottom: 30px;
        p {
            padding: 15px;
        }
        .appointment-form {
            padding: 15px;
        }
    }
    .address-and-week {
        p {
            padding: 15px;
        }
        .address-week {
            padding: 15px;
            .week li {
                font-size: 14px;
            }
        }
    }
    .about-img {
        img {
            width: 100%;
        }
        .about-img-2 {
            margin-top: 30px;
            margin-left: 0;
            margin-bottom: 30px;
            .about-post {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
    .about-content {
        margin-left: 0;
        h2 {
            font-size: 25px;
        }
        .granted {
            padding-left: 0;
            span {
                position: unset;
                line-height: 1;
            }
        }
        .default-btn {
            margin-top: 35px;
        }
    }
    .before-after-img {
        ul {
            left: 10px;
            right: 10px;
            bottom: 10px;
        }
    }
    .online-treatment-content {
        margin-left: 0;
        margin-top: 30px;
        h2 {
            font-size: 25px;
        }
    }
    .page-title-area {
        padding-top: 170px;
        padding-bottom: 100px;
        .page-title-content {
            h2 {
                font-size: 35px;
            }
            ul {
                li {
                    font-size: 15px;
                }
            }
        }
    }
    .doctors-sidebar {
        .availability {
            padding: 20px;
        }
    }
    .doctors-details {
        .doctors-history {
            padding: 20px;
            h2 {
                font-size: 30px;
            }
            span {
                font-size: 18px;
            }
        }
    }
    .services-area {
        &.services-area-two {
            .single-services {
                img {
                    width: 100%;
                }
            }
        }
    }
    .blog-details-content {
        margin-bottom: 35px;
        .blog-top-content {
            .news-content {
                h3 {
                    font-size: 20px;
                }
                .admin {
                    li {
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            blockquote {
                z-index: 1;
                margin-top: 20px;
                margin-bottom: 20px;
                p {
                    padding-left: 0;
                }
                i {
                    opacity: 0.2;
                    z-index: -1;
                }
            }
        }
        .comments {
            ul  {
                li {
                    padding-left: 0;
                    img {
                        position: unset;
                        display: block;
                    }
                    h3 {
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .leave-reply {
            h3 {
                margin-bottom: 20px;
            }
        }
    }
    .services-details-content {
        margin-top: 5px;
        .services-top-content {
            .news-content {
                h3 {
                    font-size: 20px;
                }
            }
        }
        .news-content-2 {
            h3 {
                font-size: 20px;
            }
        }
        .news-content-3 {
            h3 {
                font-size: 20px;
            }
        }
    }
    .department-details-content {
        margin-top: 5px;
        .services-top-content {
            .news-content {
                h3 {
                    font-size: 20px;
                }
            }
        }
        .news-content-2 {
            h3 {
                font-size: 20px;
            }
        }
        .news-content-3 {
            h3 {
                font-size: 20px;
            }
        }
    }
    .online-price-list {
        ul {
            li {
                .one {
                    float: unset;
                    margin-left: 0;
                }
                .two {
                    float: unset;
                }
            }
        }
    }
    .user-area-style {
        .account-title {
            h2 {
                font-size: 25px !important;
            }
        }
        .contact-form-action {
            padding: 20px;
            &.mb-50 {
                margin-bottom: 50px;
            }
            form {
                .form-group {
                    margin-bottom: 20px;
                }
                .login-action {
                    margin-bottom: 20px;
                    .forgot-login {
                        float: unset;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        text-align: left;
                        display: block;
                    }
                }
                .text-right {
                    text-align: left !important;
                }
                .default-btn {
                    margin-bottom: 16px;
                }
            }
        }
        &.recover-password-area {
            h3 {
                font-size: 30px;
            }
            .now-register {
                float: left;
            }
            p {
                margin-bottom: 20px;
            }
            .default-btn {
                margin-bottom: 0 !important;
            }
        }
    }
    .coming-soon-area {
        height: 100%;
        padding-top: 120px;
        padding-bottom: 120px;
        padding-left: 15px;
        padding-right: 15px;
        .coming-soon-content {
            padding: 30px 20px;
            h2 {
                margin-top: 25px;
                font-size: 28px;
            }
            #timer {
                margin-top: 10px;
                div {
                    width: 95px;
                    height: 100px;
                    font-size: 35px;
                    margin-top: 20px;
                    span {
                        font-size: 14px;
                    }
                }
            }
            form {
                max-width: 100%;
                margin-top: 40px;
            }
        }
    }
    .faq-accordion {
        .accordion {
            .accordion-title {
                font-size: 16px;
            }
        }
    }
    .privacy-policy {
        .title {
            h2 {
                font-size: 25px;
            }
            p {
                font-size: 15px;
            }
        }
        .privacy-content {
            margin-bottom: 25px;
            p {
                margin-bottom: 0;
            }
        }
    }
    .terms-conditions {
        .conditions-content {
            margin-bottom: 25px;
        }
        .title h2 {
            font-size: 25px;
        }
    }
    .error-area {
        padding: 70px 0;
        .error-content-wrap {
            p {
                margin: 15px 0 20px;
                font-size: 15px;
            }
            h1 {
                font-size: 100px;
            }
        }
    }
    .single-product {
        a h3 {
            font-size: 18px;
        }
    }
    .showing-result-count {
        text-align: center;
        margin-bottom: 10px;
    }
    .product-img img {
        width: 100%;
    }
    .shopping-cart-area {
        overflow: hidden;
        .cart-table {
            table {
                thead {
                    tr {
                        th {
                            font-size: 16px;
                        }
                    }
                }
            }
            table tbody tr td a {
                font-size: 14px;
            }
            text-align: center;
            table {
                thead {
                    tr {
                        th {
                            padding: 0 40px 10px;
                            white-space: nowrap;
                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
            table tbody tr td.product-subtotal {
                .remove {
                    margin-left: 15px;
                    float: unset;
                }
            }
        }
        .coupon-cart {
            .form-group .default-btn {
                position: unset;
                top: 0;
                right: 0;
                height: 50px;
                margin-top: 30px;
                margin-bottom: 30px;
                line-height: 29px;
            }
        }
        .cart-totals {
            padding: 20px;
        }
        .default-btn {
            display: block;
            &.update {
                padding: 16px 20px;
            }
        }
    }
    .checkout-area {
        .billing-details {
            margin-bottom: 30px;
            padding: 20px;
            h3 {
                font-size: 25px;
            }
        }
        .cart-totals {
            padding: 20px;
        }
        .faq-accordion {
            .default-btn {
                margin-bottom: 0;
            }
        }
        .faq-accordion {
            padding: 20px;
        }
    }
    .product-details-area {
        .section-title {
            margin: -15px auto 30px;
        }
        .product-details-image {
            margin-bottom: 20px;
        }
        .products-details-tab {
            margin-top: 30px;
            .tab_content {
                padding: 0;
                padding-top: 30px;
                .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
                    padding-right: 0;
                }
            }
        }
    }
    .portfolio-details-content {
        .portfolio-top-content {
            .news-content {
                h3 {
                    font-size: 20px;
                }
            }
            .news-content-2 {
                h3 {
                    font-size: 20px;
                }
            }
            .news-content-3 {
                h3 {
                    font-size: 20px;
                }
            }
        }
    }
    .product-view-one {
        .modal-content {
            .product-content {
                h3 {
                    a {
                        margin-top: 28px;
                    }
                }
            }
        }
    }
    .shopping-cart-area {
        .cart-totals {
            max-width: 100%;
        }
    }
    .checkout-area {
        .billing-details {
            margin-bottom: 35px;
        }
    }
    .blog-column-one {
        margin-bottom: 35px;
    }
    .blog-column-two {
        margin-bottom: 35px;
    }
    .contact-form {
        margin-bottom: 50px;
    }
    #msgSubmit {
        font-size: 16px;
    }
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .top-header {
        .header-left-content {
            margin-bottom: 0;
        }
    }
    .navbar-area {
        .others-option {
            .option-item {
                .search-overlay {
                    &.search-popup {
                        width: 300px;
                    }
                }
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    h3 {
        font-size: 20px;
    }
    .default-btn {
        font-size: 15px;
        padding: 12px 20px;
    }
    .section-title {
        span {
            font-size: 14px;
        }
        h2 {
            font-size: 30px;
        }
    }
    .top-header {
        .header-right-content {
            float: left;
            li{
                margin-right: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .banner-area {
        height: 100%;
        padding-top: 135px;
        padding-bottom: 70px;
    }
    .banner-content {
        text-align: center;
        h1 {
            font-size: 35px;
        }
    }
    .single-feature {
        padding-left: 0;
        span {
            font-size: 16px;
        }
        &.active {
            margin-top: 30px;
            margin-bottom: 30px;
            &::before {
                display: none;
            }
            img {
                width: 36px;
                top: -5px;
            }
            span {
                color: #ffffff;
                padding-left: 65px;
                top: 4px;
            }
        }
    }
    .partner-area {
        .owl-theme {
            .owl-nav {
                .owl-next {
                    right: 0;
                }
                .owl-prev {
                    left: 0;
                }
            }
        }
    }
    .choose-us-content {
        text-align: center;
    }
    .choose-us-img {
        margin-bottom: 30px;
        text-align: center;
    }
    .appointment-here-form {
        background-image: none;
        text-align: center;
        padding: 20px;
        .default-btn {
            margin-bottom: 0;
        }
    }
    .single-services {
        padding: 20px;
        .services-icon {
            width: 80px;
            height: 80px;
        }
    }
    .testimonial-img {
        margin-bottom: 30px;
    }
    .testimonial-content {
        text-align: center;
        i {
            margin-bottom: 5px;
        }
        p {
            font-size: 16px;
        }
    }
    .testimonial-area {
        .owl-carousel .owl-item img {
            margin: auto;
        }
        .owl-theme .owl-dots {
            margin-top: 30px !important;
        }
    }
    .single-doctors {
        margin-top: 0 !important;
    }
    .doctors-area {
        .owl-theme {
            .owl-nav {
                position: unset;
                margin-bottom: 30px;
            }
        }
    }
    .single-blog-post {
        .blog-content {
            padding: 20px;
        }
    }
    .single-blog-post {
        .blog-content {
            ul {
                margin-top: 10px;
            }
        }
    }
    .subscribe-bg {
        background-image: none;
        .newsletter-form {
            .default-btn {
                position: unset;
                height: 50px;
                margin: 30px auto 0;
                display: table;
            }
        }
    }
    .subscribe-content {
        text-align: center;
        padding-left: 0;
        img {
            position: unset;
            margin-bottom: 30px;
        }
    }
    .single-footer-widget {
        h3 {
            margin-bottom: 15px
        }
        img {
            margin-bottom: 20px;
        }
    }
    .copy-right-area {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .feature-area {
        .container-fluid {
            max-width: 740px;
        }
    }
    .choose-us-content-two {
        h2 {
            font-size: 25px;
        }
    }
    .address-and-week {
        position: unset;
        max-width: 100%;
    }
    .choose-us-content-two {
        margin-bottom: 30px;
    }
    .choose-us-img-two {
        img {
            margin-bottom: 30px;
        }
        &::before {
            display: none;
        }
    }
    .single-counter {
        padding-left: 0;
        text-align: center;
        i {
            font-size: 40px;
            position: unset;
            margin-bottom: 15px;
            display: block;
        }
        h2 {
            font-size: 30px;
        }
        h3 {
            font-size: 14px;
        }
    }
    .single-blog-post {
        &.mb-0 {
            margin-bottom: 30px !important;
        }
    }
    .doctors-img {
        img {
            width: 100%;
        }
    }
    .services-area {
        &.services-area-two {
            .owl-carousel {
                .owl-item {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .banner-area {
        &.banner-area-three {
            height: 100%;
            .banner-content {
                margin-left: auto;
                margin-bottom: 30px;
                h1 {
                    font-size: 35px;
                }
            }
        }
    }
    .appointment-box {
        position: unset;
        margin-bottom: 30px;
        p {
            padding: 15px;
        }
        .appointment-form {
            padding: 15px;
        }
    }
    .address-and-week {
        p {
            padding: 15px;
        }
        .address-week {
            padding: 15px;
        }
    }
    .about-img {
        img {
            width: 100%;
        }
        .about-img-2 {
            margin-top: 30px;
            margin-left: 0;
            margin-bottom: 30px;
            .about-post {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
    .about-content {
        margin-left: 0;
        h2 {
            font-size: 25px;
        }
        .granted {
            padding-left: 0;
            span {
                position: unset;
                line-height: 1;
            }
        }
        .default-btn {
            margin-top: 35px;
        }
    }
    .before-after-img {
        ul {
            left: 10px;
            right: 10px;
            bottom: 10px;
        }
    }
    .online-treatment-content {
        margin-left: 0;
        margin-top: 30px;
        h2 {
            font-size: 25px;
        }
    }
    .testimonial-area {
        .text-align-left {
            text-align: center;
        }
    }
    .choose-us-img-two {
        img {
            width: 100%;
        }
    }
    .page-title-area {
        padding-top: 170px;
        padding-bottom: 100px;
        .page-title-content {
            h2 {
                font-size: 40px;
            }
            ul {
                li {
                    font-size: 15px;
                }
            }
        }
    }
    .doctors-sidebar {
        .availability {
            padding: 20px;
        }
    }
    .doctors-details {
        .doctors-history {
            padding: 20px;
            h2 {
                font-size: 30px;
            }
            span {
                font-size: 18px;
            }
        }
    }
    .services-area {
        &.services-area-two {
            .single-services {
                img {
                    width: 100%;
                }
            }
        }
    }
    .blog-details-content {
        margin-bottom: 35px;
    }
    .services-details-content {
        margin-top: 5px;
    }
    .department-details-content {
        margin-bottom: 35px;
    }
    .user-area-style {
        .contact-form-action.mb-50 {
            margin-bottom: 50px;
        }
    }
    .checkout-area {
        .billing-details {
            margin-bottom: 35px;
        }
    }
    .blog-column-one {
        margin-bottom: 35px;
    }
    .blog-column-two {
        margin-bottom: 35px;
    }
    .contact-form {
        margin-bottom: 50px;
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width : 992px) and (max-width : 1199px) {
    .single-feature {
        padding-left: 30px;
        span {
            font-size: 16px;
        }
        &.active {
            &::before {
                bottom: -48px;
            }
        }
    }
    .appointment-here-form {
        h3 {
            font-size: 18px;
        }
    }
    .single-services {
        padding: 20px;
        .services-icon {
            width: 80px;
            height: 80px;
        }
    }
    .single-doctors {
        .doctors-content {
            padding: 20px;
            h3 {
                font-size: 20px;
            }
        }
    }
    .single-counter {
        padding-left: 55px;
        i {
            font-size: 40px;
        }
        h2 {
            font-size: 30px;
        }
        h3 {
            font-size: 14px;
        }
    }
    .top-header {
        .header-right-content {
            li {
                margin-right: 15px;
            }
        }
    }
    .banner-area {
        &.banner-area-three {
            .banner-content {
                h1 {
                    font-size: 55px;
                }
            }
        }
    }
    .address-and-week {
        p {
            padding: 20px;
        }
        .address-week {
            padding: 20px;
        }
    }
    .appointment-box {
        p {
            padding: 20px;
        }
        .appointment-form {
            padding: 20px;
        }
    }
    .about-img {
        .about-img-2 {
            margin-top: 30px;
            margin-left: 0;
            position: relative;
        }
    }
    .about-content {
        margin-left: 0;
    }
    .about-img {
        .about-img-2 {
            .about-post {
                right: 0;
                bottom: 0;
            }
        }
    }
    .online-treatment-content {
        h2 {
            font-size: 38px;
        }
    }
    .services-area {
        &.services-area-two {
            .single-services {
                .services-content {
                    padding: 20px;
                    padding-top: 20px;
                }
                img {
                    width: 100%;
                }
            }
        }
    }
    .single-portfolio .portfolio-content {
        padding: 20px;
    }
    .single-blog-post {
        .blog-content {
            padding: 20px;
        }
        .blog-img {
            .date {
                right: 20px;
            }
        }
    }
}

/* Max width 1700px */
@media only screen and (min-width: 1700px) {
    .header-area {
        margin-left: 100px;
        margin-right: 100px;
        &.header-area-two {
            margin-left: 0;
            margin-right: 0;
        }
        &.header-area-three {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .banner-area {
        margin-left: 100px;
        margin-right: 100px;
        height: 700px;
        &.banner-area-two {
            margin-left: 0;
            margin-right: 0;
        }
        &.banner-area-three {
            margin-left: 0;
            margin-right: 0;
        }
        &.bg-3 {
            height: 790px;
        }
    }
    .feature-area {
        margin-left: 100px;
        margin-right: 100px;
        &.feature-area-two {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .our-project-area {
        margin-left: 100px;
        margin-right: 100px;
        &.our-project-area-three {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .footer-area {
        margin-left: 100px;
        margin-right: 100px;
        &.footer-area-two {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .copy-right-area {
        margin-left: 100px;
        margin-right: 100px;
        &.copy-right-area-two {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

/* Only Mobil Menu Max width 991px */
@media only screen and (max-width: 991px) {
    .mobile-nav {
        display: block;
        position: relative;
        .logo {
            text-decoration: none;
            position: absolute;
            top: 19px;
            z-index: 999;
            left: 15px;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 20px;
            max-width: 145px;
        }
        nav {
            .navbar-nav {
                height: 300px;
                overflow-y: scroll !important;
                .nav-item {
                    a {
                        i {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .mean-container {
        .mean-bar {
            background-color: #0E6A6A;
            box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.05);
            height: 70px;
            padding: 0;
        }
        .mean-nav {
            margin-top: 70px;
            ul {
                li {
                    a {
                        &:hover {
                            color: #ee3232;
                        }
                        &.active {
                            color: #ee3232;
                        }
                    }
                }
            }
        }
        a {
            &.meanmenu-reveal {
                padding: 23px 15px 0 0;
                color: #272b2b;
                span {
                    background-color: #272b2b;
                    position: relative;
                    top: 8px;
                    margin-top: -5px;
                }
            }
        }
    }
    .main-nav {
        display: none !important;
    }
    .others-option-for-responsive {
        display: block;
        .container {
            max-width: 100%;
            .container {
                max-width: 400px;
                margin: auto;
            }
        }
        .option-inner {
            .others-option {
                background-color: #13316e;
                margin-right: 15px;
                .dot-menu:hover {
                    .inner {
                        .circle {
                            background-color: #ffffff;
                        }
                    }
                }
                .cart-icon {
                    a {
                        i {
                            color: #ffffff;
                        }
                        span {
                            background-color: #ffffff !important;
                            color: #13316e;
                        }
                    }
                }

                .search-btn {
                    color: #ffffff;
                    &:hover {
                        color: #ffffff !important;
                    }
                }
                .close-btn {
                    color: #ffffff;
                }

            }
        }
    }
    .navbar-area {
        .others-option {
            display: none;
        }
    }
    .top-header {
        &.top-header-three {
            .appointment {
                display: none;
            }
        }
    }
}

/*
Continuer Custom Width Area Style*/
@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1260px;
    }
    .container-fluid {
        max-width: 1470px;
    }
}
